import React from 'react';
import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import '../styles/privacypolicy.css';

function PrivacyPolicy() {
  return (
    <>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle privacy-policy'>
        <TopBar />
        <div className='content'>
          <h1>Privacy Policy</h1>
          <p>Welcome to Itinerify.com. We value your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we collect, use, and share your personal information when you visit our website and use our services.</p>
          
          <h2>Data Collection</h2>
          <h3>Information You Provide to Us</h3>
          <p>We collect personal information that you voluntarily provide to us when you register on Itinerify.com, express an interest in obtaining information about us or our products and services, when you participate in activities on the platform (such as posting messages in our online forums or entering competitions, contests, or giveaways) or otherwise contacting us.</p>
          <p>The personal information that we collect depends on the context of your interactions with us and the platform, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
          <ul>
            <li>Personal Identifiers: Name, email address, phone number, and username.</li>
            <li>Profile Information: Profile photo, travel interests, and bio.</li>
            <li>Travel Information: Travel itineraries, accommodation details, expenses, and shared itineraries.</li>
          </ul>

          <h3>Information Collected Automatically</h3>
          <p>We automatically collect certain information when you visit, use, or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our services, and other technical information. This information is primarily needed to maintain the security and operation of our platform, and for our internal analytics and reporting purposes.</p>

          <h3>Information from Third Parties</h3>
          <p>We may obtain information about you from other sources, such as social media platforms like Google, when you log in using your social media account. This data may include your username, profile picture, and other public profile information.</p>

          <h2>Use of Your Information</h2>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li>To facilitate account creation and the logon process.</li>
            <li>To post testimonials.</li>
            <li>To request feedback.</li>
            <li>To enable user-to-user communications.</li>
            <li>To manage user accounts.</li>
            <li>To send administrative information to you.</li>
            <li>To protect our services.</li>
            <li>To enforce our terms, conditions, and policies.</li>
            <li>To respond to legal requests and prevent harm.</li>
            <li>For other business purposes.</li>
          </ul>

          <h2>Sharing Your Information</h2>
          <p>We may process or share your data that we hold based on the following legal basis:</p>
          <ul>
            <li>Consent.</li>
            <li>Legitimate Interests.</li>
            <li>Performance of a Contract.</li>
            <li>Legal Obligations.</li>
            <li>Vital Interests.</li>
          </ul>
          <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
          <ul>
            <li>Business Transfers.</li>
            <li>Business Partners.</li>
          </ul>

          <h2>Data Retention</h2>
          <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy unless a longer retention period is required or permitted by law. No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>

          <h2>Security of Your Information</h2>
          <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our platform is at your own risk. You should only access the services within a secure environment.</p>

          <h2>Your Privacy Rights</h2>
          <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right:</p>
          <ul>
            <li>To request access and obtain a copy of your personal information.</li>
            <li>To request rectification or erasure.</li>
            <li>To restrict the processing of your personal information.</li>
            <li>To data portability.</li>
          </ul>
          <p>In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>

          <h2>Updates to This Policy</h2>
          <p>We may update this privacy policy from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons.</p>

          <h2>Contact Us</h2>
          <p>If you have questions or comments about this policy, you may email us at info@itinerify.com or by post to:</p>
          <p>Itinerify.com<br/>
          London Road, London</p>
        </div>
        <BottomNav />
      </div>
    </>
  );
}

export default PrivacyPolicy;
