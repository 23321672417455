import React, { useState, useEffect } from 'react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import { useParams } from 'react-router-dom';

import { db } from '../../config/firebase';
import { collection, getDoc, getDocs, doc, deleteDoc, updateDoc, where, query } from 'firebase/firestore';

import { useAuth } from '../../components/AuthContext';

import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import BottomNav from '../../components/BottomNav';

import "leaflet/dist/leaflet.css";

import '../../styles/itinerary.css';

function Itinerary() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();

  // Get the username from URL parameters
  const { id } = useParams();

  const [userData, setUserData] = useState();
  const [activities, setActivities] = useState();
  const [accommodationTypes, setaccommodationTypes] = useState();
  const [publicUserData, setPublicUserData] = useState();
  const [loadingPublicUserData, setLoadingPublicUserData] = useState(true);
  const [itineraryData, setItineraryData] = useState();
  const [loadingItineraryData, setLoadingItineraryData] = useState(true);
  const [daysData, setDaysData] = useState();
  const [loadingDaysData, setLoadingDaysData] = useState(true);

  const usersCollectionRef = collection(db, "users");
  const activitiesCollectionRef = collection(db, "activities");
  const accommodationTypesCollectionRef = collection(db, "accommodationTypes");
  const itineraryDocRef = doc(db, 'itineraries', id);
  const daysCollectionRef = collection(db, "days");

  const customIcon = new L.Icon({
    iconUrl: '../../../map-pin-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
  });

  const customAccommodationIcon = new L.Icon({
    iconUrl: '../../../map-pin-acc-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
  });

  useEffect(() => {
    const fetchData = async () => {
      if(user) {
        try {
          // Read user data from the database
          const usersSnapshot = await getDocs(usersCollectionRef);

          // Find the document corresponding to the current user
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);

          if (filteredUserDoc) {
            // Extract user data from the document
            const userData = {
              id: filteredUserDoc.id,
              ...filteredUserDoc.data()
            };
            setUserData(userData);
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [user]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read activities data from the database
        const activitiesSnapshot = await getDocs(activitiesCollectionRef);
        const activities = activitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setActivities(activities);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read accommodation types data from the database
        const accommodationTypesSnapshot = await getDocs(accommodationTypesCollectionRef);
        const accommodationTypes = accommodationTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setaccommodationTypes(accommodationTypes);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);  

  // Find user data related to the current itinerary
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingPublicUserData(true);
        if(itineraryData) {
          // Read user data from the database
          const usersSnapshot = await getDocs(usersCollectionRef);
          // Find the document corresponding to the current user
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === itineraryData.user.id);

          if (filteredUserDoc) {
            // Extract user data from the document
            const pUserData = {
              id: filteredUserDoc.id,
              ...filteredUserDoc.data()
            };
            setPublicUserData(pUserData);
            setLoadingPublicUserData(false);
          } else {
            setLoadingPublicUserData(false);
            console.error('User document not found');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [itineraryData]);

  // Find itinerary
  useEffect(() => {
    const fetchData = async () => {
      setLoadingItineraryData(true);
      setLoadingDaysData(true);
      if(id) {
        try {
          const itineraryDocSnapshot = await getDoc(itineraryDocRef);

          if(itineraryDocSnapshot.exists()) {
            setItineraryData({id: itineraryDocSnapshot.id, ...itineraryDocSnapshot.data()});
          } else {
            console.log('No such document!');
          }

          setLoadingItineraryData(false);

          // Orderby date is not working
          const daysDocsSnapshot = await getDocs(query(daysCollectionRef, where("itinerary", '==', itineraryDocRef)));
          const daysData = [];

          daysDocsSnapshot.forEach(doc => {
              daysData.push({id: doc.id, ...doc.data()});
          });

          // So we sort by date here
          daysData.sort((a, b) => {
            return a.date.toMillis() - b.date.toMillis(); // Sort in descending order
          });

          setDaysData(daysData);

          setLoadingDaysData(false);
        } catch (error) {
          setLoadingItineraryData(false);
          setLoadingDaysData(false);
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [id]);  

  // Function to handle the delete operation of the itinerary
  const handleDeleteItinerary = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this itinerary?');

    // If confirmed, proceed with document deletion
    if (isConfirmed) {
      const itineraryDocRef = doc(db, 'itineraries', id);
      try {
        await deleteDoc(itineraryDocRef);
        alert('Itinerary successfully deleted!');
        window.location.href = '/';
      } catch (error) {
        console.error('Error removing document: ', error);
      }
    }
  };

  const handleDeleteDay = async (dayId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this day?');
  
    if (isConfirmed) {
      const dayDocRef = doc(db, 'days', dayId);
      try {
        // Delete the day document
        await deleteDoc(dayDocRef);
        alert('Day successfully deleted!');
  
        // Update the state to remove the deleted day
        const updatedDaysData = daysData.filter(day => day.id !== dayId);
        setDaysData(updatedDaysData);
  
        // Recalculate the budget after deletion
        let accommodationsBudget = 0;
        let activitiesBudget = 0;
        let otherExpensesBudget = 0;
  
        updatedDaysData.forEach(day => {
          if (day.accommodation) {
            accommodationsBudget += parseFloat(day.accommodation.cost);
          }
  
          day.places.forEach(place => {
            activitiesBudget += parseFloat(place.activityPrice);
            place.expenses.forEach(expense => {
              otherExpensesBudget += parseFloat(expense.amount);
            });
          });
        });
  
        // Get the current itinerary data for flight costs
        const itinerarySnapshot = await getDoc(itineraryDocRef);
        const itineraryData = itinerarySnapshot.data();
  
        // Calculate the new total budget
        const totalBudget = accommodationsBudget + activitiesBudget + otherExpensesBudget + parseFloat(itineraryData.departureFlightCost) + parseFloat(itineraryData.returnFlightCost);
  
        // Update the itinerary document with the new budget
        await updateDoc(itineraryDocRef, {
          budget: totalBudget,
          accommodationsBudget,
          activitiesBudget,
          otherExpensesBudget,
          nDays: updatedDaysData.length
        });
  
        setItineraryData({
          ...itineraryData,
          budget: totalBudget,
          accommodationsBudget,
          activitiesBudget,
          otherExpensesBudget,
          nDays: updatedDaysData.length
        });
  
      } catch (error) {
        console.error('Error removing document or updating budget: ', error);
      }
    }
  };  

  return (
    <div className='row'>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle'>
        <TopBar />
        {!loadingPublicUserData && !loadingItineraryData && !loadingDaysData ? (
          <div className='itinerary'>
            <div className='itinerary-column-description-left'>
              <div className='square-img'>
                <img src={itineraryData.coverImage} />
              </div>
            </div>
            <div className='itinerary-column-description-right'>
              <a href={`/${publicUserData.username}`}><p>@{publicUserData.username}</p></a>
              <p style={{ fontSize: '14px', color: 'rgb(197, 197, 197)' }}>{itineraryData.creationDate.toDate().toLocaleDateString()}</p>
              <br />
              <h2 style={{ display: 'inline' }}>{itineraryData.title}</h2>{" "}
              {userData && userData.id === itineraryData.user.id && (
                <>
                  <a href={`/itinerary/edit/${itineraryData.id}`} style={{ display: 'inline' }}><button>Edit Itinerary</button></a>{" "}
                  <button style={{ display: 'inline' }} onClick={() => {handleDeleteItinerary()}}>Delete Itinerary</button>
                </>
              )}
              <br />
              <p>
                {daysData.length > 0 ? (
                  daysData.length === 1 ? 
                    `${daysData.length} day itinerary | ${daysData[0].date.toDate().toLocaleDateString()}` :
                    `${daysData.length} days itinerary | ${daysData[0].date.toDate().toLocaleDateString()} - ${daysData[daysData.length - 1].date.toDate().toLocaleDateString()}`
                ) : (
                  'No days available'
                )}
              </p>
              <br />
              <p><b>📍 Destination:</b> {itineraryData.destinationCountry}</p>
              <p>
                <b>🧑 Participants:</b>{' '}
                {itineraryData.participants.adult} {itineraryData.participants.adult === 1 ? 'adult, ' : ' adults, '}
                {itineraryData.participants.children} {itineraryData.participants.children === 1 ? 'child' : ' children'}
              </p>
              <p>
                <b>💰 Total Budget:</b> {itineraryData.currency.symbol}{itineraryData.budget}
              </p>
              <br />
              <p><b>Budget Brakedown</b></p>
              <p>🏠 Accommodations {itineraryData.currency.symbol}{itineraryData.accommodationsBudget ? itineraryData.accommodationsBudget : 0} | Activities 🏄 {itineraryData.currency.symbol}{itineraryData.activitiesBudget ? itineraryData.activitiesBudget : 0} | Flights ✈️ {itineraryData.currency.symbol}{parseFloat(itineraryData.departureFlightCost) + parseFloat(itineraryData.returnFlightCost)} | 🛍️ Other Expenses {itineraryData.currency.symbol}{itineraryData.otherExpensesBudget ? itineraryData.otherExpensesBudget : 0}</p>
              <br />
              <p>{itineraryData.shortDescription}</p>
            </div>
            {/* If current user is itinerary owner, can add a date */}
            <div className='add-a-day'>
              {userData && 
                userData.id === itineraryData.user.id && (
                  <a href={`/itinerary/new/day/${itineraryData.id}`}><button>Add a Day</button></a>
                )
              }
            </div>
            <div className='days-section'>
              {daysData && daysData.map((day, index) => {

                const formattedDate = day.date.toDate().toLocaleDateString('en-UK', { day: 'numeric', month: 'short', year: 'numeric' });

                return (
                  <>
                    <h2 style={{ display: 'inline' }}>Day {index+1} - {formattedDate}</h2>{" "}
                    {userData && userData.id === itineraryData.user.id && (
                      <>
                        <a href={`/itinerary/edit/day/${day.id}`} style={{ display: 'inline' }}><button>Edit Day</button></a>{" "}
                        <button style={{ display: 'inline' }} className='button-red' onClick={() => handleDeleteDay(day.id)}>Delete Day</button>
                      </>
                    )}
                    <h3>{day.title}</h3>
                    <br />
                    <p><b>🏠 Accommodation ({itineraryData.currency.symbol}{day.accommodation && day.accommodation.cost})</b>: {day.accommodation ? day.accommodation.place.display_name : 'No accommodation'}</p>
                    <br />
                    <div className='row'>
                      <div className='column-day'>
                        {day.places.map((place, index) => {
                          return (
                            <>
                              <h4>{index+1 + '. ' + (place.name != '' ? place.name : place.display_name.split(',')[0] + ', ' + place.display_name.split(',')[1])}</h4>
                              <p>Activity: {activities && activities.filter(act => act.id === place.activity.id)[0].name}</p>
                              <p>Activity Price: {place.activityPrice > 0 ? itineraryData.currency.symbol + place.activityPrice : 'FREE'}</p>
                              <p>Other Expenses: {itineraryData.currency.symbol + place.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)} ({place.expenses.map(expense => expense.description).join(', ')})</p>
                              <p>{ Array.from({ length: 5 }, (_, index) => ( index < place.rating ? <span className='review-star'>★</span> : '☆' )) }</p>
                              <br />
                            </>
                          );
                        })}
                      </div>
                      <div className='column-map'>
                        <MapContainer center={[ day.places[0].lat, day.places[0].lon ]} zoom={11} scrollWheelZoom={true} style={{ height: 500 }}>
                          <TileLayer
                            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          />
                          {day.accommodation && (
                            <Marker key={index} position={[parseFloat(day.accommodation.place.lat), parseFloat(day.accommodation.place.lon)]} icon={customAccommodationIcon}>
                              <Popup>
                                <h4>{day.accommodation.place.name}</h4>
                                <p>{accommodationTypes.filter(acc => acc.id === day.accommodation.type.id)[0].name}</p>
                              </Popup>
                            </Marker>
                          )}
                          {day.places.map((place, index) => (
                            <Marker key={index} position={[parseFloat(place.lat), parseFloat(place.lon)]} icon={customIcon}>
                              <Popup>
                                <h4>{place.name != '' ? place.name : place.display_name.split(',')[0] + ', ' + place.display_name.split(',')[1]}</h4>
                                <p>Activity Price: {place.activityPrice > 0 ? itineraryData.currency.symbol + place.activityPrice : 'FREE'}</p>
                                <p>Rating: { Array.from({ length: 5 }, (_, index) => ( index < place.rating ? <span className='review-star'>★</span> : '☆' )) }</p>
                                <a href={`https://www.google.com/maps/search/${place.display_name}`}><button>Take me there!</button></a>
                              </Popup>
                            </Marker>
                          ))}
                        </MapContainer>
                      </div>
                    </div>
                    <hr />
                    <br /><br />
                  </>
                )
              })}
            </div>
            <div className='share-section'>
              <h3>Share this itinerary:</h3>
              <div className='share-buttons'>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className='share-button fb-share'>Facebook</button>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=Check out this amazing itinerary!`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className='share-button x-share'>X</button>
                </a>
                <a
                  href={`https://www.instagram.com/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className='share-button ig-share'>Instagram</button>
                </a>
              </div>
            </div>
            <br /><br />
          </div>
        ) : (
          <div className="loading-animation"></div>
        )}
        <BottomNav />
      </div>
      <div className='column-layout-right'></div>
    </div>
  )
}

export default Itinerary;
