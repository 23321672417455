import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { collection, getDocs, getDoc, doc, query } from 'firebase/firestore';
import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import '../styles/home.css';

function Search () {
  const [itinerariesData, setItinerariesData] = useState([]);
  const [loadingItinerariesData, setLoadingItinerariesData] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const itinerariesCollectionRef = collection(db, "itineraries");

  useEffect(() => {
    filterItineraries();
  }, []);

  const filterItineraries = async () => {
    setLoadingItinerariesData(true);
    try {
      const q = query(itinerariesCollectionRef);
      const itinerariesSnapshot = await getDocs(q);
      const itData = [];

      await Promise.all(itinerariesSnapshot.docs.map(async (itinerary) => {
        const data = itinerary.data();
        const userSnapshot = await getDoc(doc(db, 'users', data.user.id));
        const groupTypeSnapshot = await getDoc(doc(db, 'groupTypes', data.groupType.id));
        const categorySnapshot = await getDoc(doc(db, 'categories', data.category.id));
        const uData = {
          id: itinerary.id,
          ...userSnapshot.data(),
          groupType: groupTypeSnapshot.data(),
          category: categorySnapshot.data()
        };
        itData.push({ ...data, ...uData });
      }));

      if (searchQuery !== '') {
        setItinerariesData(itData.filter(itinerary => itinerary.title.toLowerCase().includes(searchQuery.toLowerCase())));
      } else {
        setItinerariesData(itData);
      }

      setLoadingItinerariesData(false);
    } catch (error) {
      console.error('Error fetching itineraries data:', error);
      setLoadingItinerariesData(false);
    }
  };

  useEffect(() => {
    filterItineraries();
  }, [searchQuery]);

  return (
    <>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle home'>
        <TopBar />
        <div>
          <h3 style={{ textAlign: 'center' }}>Search by name 🔍</h3>
          <br />
          <input
            type="text"
            style={{ width: '50%', margin: '0px 25% 0px 25%' }}
            placeholder='Insert itinerary name...'
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <br /><br />
        {loadingItinerariesData ? (
          <div className="loading-animation"></div>
        ) : (
          itinerariesData.filter(itinerary => itinerary.visibility).map(itinerary => (
            itinerary.nDays && itinerary.nDays > 0 && (
              <div className='post' key={itinerary.id}>
                <a href={`/${itinerary.username}`}>
                  <p><b>@{itinerary.username}</b></p>
                </a>
                <p className='date'>{itinerary.creationDate.toDate().toLocaleDateString()}</p>
                <p className='destination'>{itinerary.destinationCountry}</p>
                <a href={`/itinerary/${itinerary.id}`}>
                  <div className='square-img'>
                    <img src={itinerary.coverImage} alt={itinerary.title} />
                  </div>
                </a>
                <h3 className='title'>
                  {itinerary.title + ' | ' + itinerary.currency.symbol + itinerary.budget}
                </h3>
                <h4>{itinerary.groupType.name}, {itinerary.category.name}</h4>
                <br />
                <p>📅 {itinerary.nDays} days itinerary</p>
                {itinerary.tookFlight ? (
                  <p>✈️ {itinerary.departureAirport.name} ➡️ {itinerary.returnAirport.name}</p>
                ) : (
                  <p>✈️ Did not take a flight</p>
                )}
                <p>🏠 Accommodations: {itinerary.currency.symbol + parseFloat(itinerary.accommodationsBudget)} 🏄 Activities: {itinerary.currency.symbol + parseFloat(itinerary.activitiesBudget)}</p>
                <br />
                <p>{itinerary.shortDescription}</p>
                <br />
                <hr />
              </div>
            )
          ))
        )}
        <BottomNav />
      </div>
    </>
  )
}

export default Search;