import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { collection, getDocs, getDoc, doc, orderBy, query, where } from 'firebase/firestore';
import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';
import '../styles/home.css';

function Home() {
  const [itinerariesData, setItinerariesData] = useState([]);
  const [loadingItinerariesData, setLoadingItinerariesData] = useState(true);
  const [groupTypes, setGroupTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedGroupType, setSelectedGroupType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [maxBudget, setMaxPrice] = useState(0);

  const itinerariesCollectionRef = collection(db, "itineraries");
  const groupTypesCollectionRef = collection(db, "groupTypes");
  const categoriesCollectionRef = collection(db, "categories");

  useEffect(() => {
    filterItineraries();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(groupTypesCollectionRef, orderBy('name'));
        const groupTypesSnapshot = await getDocs(q);
        const groupTypes = groupTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGroupTypes(groupTypes);
      } catch (error) {
        console.error('Error fetching group types data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(categoriesCollectionRef, orderBy('name'));
        const categoriesSnapshot = await getDocs(q);
        const categories = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCategories(categories);
      } catch (error) {
        console.error('Error fetching categories data:', error);
      }
    };

    fetchData();
  }, []);

  const filterItineraries = async () => {
    setLoadingItinerariesData(true);
    try {
      let filters = [];

      if (selectedGroupType && selectedGroupType !== 'Group Type') {
        filters.push(where('groupType', '==', doc(db, 'groupTypes', selectedGroupType)));
      }
      if (selectedCategory && selectedCategory !== 'All') {
        filters.push(where('category', '==', doc(db, 'categories', selectedCategory)));
      }
      if (maxBudget) {
        filters.push(where('budget', '<=', parseFloat(maxBudget)));
      }

      const q = filters.length ? query(itinerariesCollectionRef, ...filters) : itinerariesCollectionRef;

      const itinerariesSnapshot = await getDocs(q);
      const itData = [];

      await Promise.all(itinerariesSnapshot.docs.map(async (itinerary) => {
        const data = itinerary.data();
        const userSnapshot = await getDoc(doc(collection(db, 'users'), data.user.id));
        const groupTypeSnapshot = await getDoc(doc(collection(db, 'groupTypes'), data.groupType.id));
        const categorySnapshot = await getDoc(doc(collection(db, 'categories'), data.category.id));
        const uData = { id: itinerary.id, ...userSnapshot.data(), groupType: groupTypeSnapshot.data(), category: categorySnapshot.data() };
        itData.push({ ...data, ...uData });
      }));

      // Sort itineraries by creationDate
      itData.sort((a, b) => b.creationDate.toDate() - a.creationDate.toDate());

      setItinerariesData(itData);
      setLoadingItinerariesData(false);
    } catch (error) {
      console.error('Error fetching itineraries data:', error);
      setLoadingItinerariesData(false);
    }
  };

  useEffect(() => {
    filterItineraries();
  }, [selectedGroupType, selectedCategory, maxBudget]);

  return (
    <>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle home'>
        <TopBar />
        <div>
          <h3 style={{ textAlign: 'center' }}>Filters</h3>
          <br />
          <select
            id="groupTypesFilter"
            onChange={(e) => setSelectedGroupType(e.target.value)}
          >
            <option>Group Type</option>
            {groupTypes && groupTypes.map((groupType, index) => (
              <option key={index} value={groupType.id}>{groupType.name}</option>
            ))}
          </select>
          <br /><br />
          <label style={{ width: '50%', margin: '0px 25% 0px 25%' }}>
            My Budget: {maxBudget != 0 ? maxBudget > 9999 ? '10000+' : maxBudget : '0 (All)'}
            <br />
            <input
              type="range"
              min="0"
              max="10000"
              value={maxBudget}
              style={{ width: '50%', margin: '0px 25% 0px 25%' }}
              onChange={(e) => setMaxPrice(parseInt(e.target.value))}
              step="100"
            />
          </label>
        </div>
        <br />
        {!loadingItinerariesData ? (
          itinerariesData.filter(itinerary => itinerary.visibility).map(itinerary => (
            itinerary.nDays && itinerary.nDays > 0 && (
              <div className='post' key={itinerary.id}>
                <a href={`/${itinerary.username}`}>
                  <p><b>@{itinerary.username}</b></p>
                </a>
                <p className='date'>{itinerary.creationDate.toDate().toLocaleDateString()}</p>
                <p className='destination'>{itinerary.destinationCountry}</p>
                <a href={`/itinerary/${itinerary.id}`}>
                  <div className='square-img'>
                    <img src={itinerary.coverImage} alt={itinerary.title} />
                    <div className='click-overlay'>Click on me!</div>
                  </div>
                </a>
                <h3 className='title'>
                  {itinerary.title + ' | ' + itinerary.currency.symbol + itinerary.budget}
                </h3>
                <h4>{itinerary.groupType.name}, {itinerary.category.name}</h4>
                <br />
                <p>📅 {itinerary.nDays} days itinerary</p>
                {itinerary.tookFlight ? (
                  <p>✈️ {itinerary.departureAirport.name} ➡️ {itinerary.returnAirport.name}</p>
                ) : (
                  <p>✈️ Did not take a flight</p>
                )}
                <p>🏠 Accommodations: {itinerary.currency.symbol + parseFloat(itinerary.accommodationsBudget)} 🏄 Activities: {itinerary.currency.symbol + parseFloat(itinerary.activitiesBudget)}</p>
                <br />
                <p>{itinerary.shortDescription}</p>
                <br />
                <hr />
              </div>
            )
          ))
        ) : (
          <div className="loading-animation"></div>
        )}
        <BottomNav />
      </div>
      <div className='column-layout-right right-info'>
        <h3>Categories</h3>
        <br />
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setSelectedCategory('');
          }}
        >
          {!selectedCategory ? <b><p>All</p></b> : <p>All</p>}
        </a>
        {categories && (
          categories.map(category => (
            <a
              key={category.id}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedCategory(category.id);
              }}
            >
              {selectedCategory === category.id ? <b><p>{category.name}</p></b> : <p>{category.name}</p>}
            </a>
          ))
        )}
      </div>
    </>
  )
}

export default Home;