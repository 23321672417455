import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';

import Home from './views/Home';

import Login from './views/Login';
import Signup from './views/Signup';

import Profile from './views/Profile';

import Itinerary from './views/itinerary/Itinerary';
import NewItinerary from './views/itinerary/New';
import EditItinerary from './views/itinerary/Edit';
import NewDay from './views/itinerary/NewDay';
import EditDay from './views/itinerary/EditDay';

import Search from './views/Search';

import PrivacyPolicy from './views/PrivacyPolicy';

import NotFound from './views/NotFound';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/:username" element={<Profile />} />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          <Route path="/itinerary/:id" element={<Itinerary />} />
          <Route path="/itinerary/new" element={<NewItinerary />} />
          <Route path="/itinerary/edit/:itineraryId" element={<EditItinerary />} />
          <Route path="/itinerary/new/day/:itineraryId" element={<NewDay />} />
          <Route path="/itinerary/edit/day/:dayId" element={<EditDay />} />

          <Route path="/search" element={<Search />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="*" element={<NotFound />} /> {/* Not found route */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;