import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';
import { db } from '../../config/firebase';
import { collection, doc, getDocs, updateDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';

import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import BottomNav from '../../components/BottomNav';
import Footer from '../../components/Footer';

const worldCurrencies = [{ name: 'Euro', symbol: '€' }, { name: 'United States dollar', symbol: '$' }, { name: 'Australian dollar', symbol: '$' },
  { name: 'Sterling', symbol: '£' }, { name: 'Eastern Caribbean dollar', symbol: '$' }, { name: 'West African CFA franc', symbol: 'Fr' },
  { name: 'New Zealand dollar', symbol: '$' }, { name: 'Norwegian krone', symbol: 'kr' }, { name: 'Central African CFA franc', symbol: 'Fr' },
  { name: 'South African rand', symbol: 'R' }, { name: 'CFP franc', symbol: '₣' }, { name: 'Chilean peso', symbol: '$' },
  { name: 'Danish krone', symbol: 'kr' }, { name: 'Indian rupee', symbol: '₹' }, { name: 'Turkish lira', symbol: '₺' }, { name: 'Algerian dinar', symbol: 'DA' },
  { name: 'Mauritanian ouguiya', symbol: 'UM' }, { name: 'Moroccan dirham', symbol: 'DH' }, { name: 'Israeli new shekel', symbol: '₪' }, { name: 'Jordanian dinar', symbol: 'د.أ' },
  { name: 'Brunei dollar', symbol: 'B$' }, { name: 'Singapore dollar', symbol: '₽' }, { name: 'Hong Kong dollar', symbol: '₽' }, { name: 'Swiss franc', symbol: 'Fr' },
  { name: 'Netherlands Antillean guilder', symbol: 'ƒ' }, { name: 'Saint Helena pound', symbol: '£' }, { name: 'Falkland Islands pound', symbol: '£' },
  { name: 'Canadian Dollar', symbol: '$' }
];

function EditItinerary() {
  const { user } = useAuth();
  const { itineraryId } = useParams();
  const storage = getStorage();

  const [userData, setUserData] = useState();
  const [groupTypes, setGroupTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [currency, setCurrency] = useState('');
  const [tookFlight, setTookFlight] = useState(false); // New state for flight selection
  const [destinationCountryInputValue, setDestinationCountryInputValue] = useState('');
  const [destinationCountriesFound, setDestinationCountriesFound] = useState([]);
  const [destinationCountry, setDestinationCountry] = useState(null);
  const [departureAirportInputValue, setDepartureAirportInputValue] = useState('');
  const [departureAirportsFound, setDepartureAirportsFound] = useState([]);
  const [departureAirport, setDepartureAirport] = useState(null);
  const [departureFlightCost, setDepartureFlightCost] = useState(0);
  const [returnAirportInputValue, setReturnAirportInputValue] = useState('');
  const [returnAirportsFound, setReturnAirportsFound] = useState([]);
  const [returnAirport, setReturnAirport] = useState(null);
  const [returnFlightCost, setReturnFlightCost] = useState(0);
  const [groupType, setGroupType] = useState('');
  const [category, setCategory] = useState('');
  const [adult, setAdult] = useState(1);
  const [children, setChildren] = useState(0);
  const [coverImage, setCoverImage] = useState(null);
  const [visibility, setVisibility] = useState(true);

  const usersCollectionRef = collection(db, "users");
  const groupTypesCollectionRef = collection(db, "groupTypes");
  const categoriesCollectionRef = collection(db, "categories");
  const itineraryDocRef = doc(db, 'itineraries', itineraryId);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const usersSnapshot = await getDocs(usersCollectionRef);
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);
          if (filteredUserDoc) {
            setUserData({ id: filteredUserDoc.id, ...filteredUserDoc.data() });
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchGroupTypes = async () => {
      try {
        const groupTypesSnapshot = await getDocs(groupTypesCollectionRef);
        setGroupTypes(groupTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching group types:', error);
      }
    };
    fetchGroupTypes();
  }, []);  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(categoriesCollectionRef);
        setCategories(categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchItineraryData = async () => {
      if (itineraryId) {
        try {
          const itineraryDocSnapshot = await getDoc(itineraryDocRef);
          if (itineraryDocSnapshot.exists()) {
            const data = itineraryDocSnapshot.data();
            setTitle(data.title);
            setShortDescription(data.shortDescription);
            setCurrency(worldCurrencies.findIndex(c => c.name === data.currency.name));
            setDepartureAirport(data.departureAirport);
            setDepartureFlightCost(data.departureFlightCost);
            setReturnAirport(data.returnAirport);
            setReturnFlightCost(data.returnFlightCost);
            setAdult(data.participants.adult);
            setChildren(data.participants.children);
            setGroupType(data.groupType.id);
            setCategory(data.category.id);
            setVisibility(data.visibility);
            setTookFlight(data.departureAirport !== null || data.returnAirport !== null);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching itinerary data:', error);
        }
      }
    };

    fetchItineraryData();
  }, [itineraryId]);

  const handleCountrySearch = async (input) => {
    if (input) {
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json&addressdetails=1`);
        const countries = response.data.filter(place => place.addresstype === 'country');
        if (countries.length > 0) {
          setDestinationCountriesFound(countries);
          setDestinationCountry(countries[0]);
        } else {
          alert('No countries found!');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
        setDestinationCountriesFound([]);
      }
    } else {
      alert("Empty input!");
    }
  };

  async function getAirports(input, setAirportsFound, setAirport) {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json`);
      const airports = response.data.filter(place => place.type === 'aerodrome');
      if (airports.length > 0) {
        setAirportsFound(airports);
        setAirport(airports[0]);
      } else {
        alert("No airports found!");
      }
    } catch (error) {
      console.error('Error fetching airports:', error);
    }
  }

  const handleAirportSearchButton = (inputValue, setAirportsFound, setAirport) => {
    if (inputValue) {
      getAirports(inputValue, setAirportsFound, setAirport);
    } else {
      alert("Empty input!");
    }
  };

  const handleSelectAirport = (selectedAirport, airportsFound, setAirport) => {
    setAirport(airportsFound[selectedAirport]);
  };

  async function handleUpdate() {
    if (title && (tookFlight ? (departureAirport && returnAirport && departureFlightCost && returnFlightCost) : destinationCountry) && groupType && category && visibility !== null) {
      try {
        let downloadURL = '';
  
        if (coverImage) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true
          };
  
          const compressedFile = await imageCompression(coverImage, options);
  
          const imagesUploadRef = ref(storage, `images/${new Date().toISOString() + compressedFile.name}`);
          await uploadBytes(imagesUploadRef, compressedFile);
          downloadURL = await getDownloadURL(imagesUploadRef);
        }
  
        const itineraryDocSnapshot = await getDoc(itineraryDocRef);
        if (itineraryDocSnapshot.exists()) { 
          const data = itineraryDocSnapshot.data();
  
          // Calculate the new budget
          var totalBudget = (data.budget - data.departureFlightCost - data.returnFlightCost) + departureFlightCost + returnFlightCost;
  
          const updateData = {
            title,
            shortDescription,
            currency: worldCurrencies[currency],
            budget: totalBudget,
            tookFlight,
            departureAirport,
            departureFlightCost,
            returnAirport,
            returnFlightCost,
            destinationCountry: tookFlight ? returnAirport.display_name.split(',').pop().trim() : destinationCountry.display_name,
            groupType: doc(db, 'groupTypes', groupType),
            category: doc(db, 'categories', category),
            participants: { adult, children },
            visibility: visibility,
          };
  
          if (coverImage) {
            updateData.coverImage = downloadURL;
          }
  
          await updateDoc(itineraryDocRef, updateData);
          alert('Itinerary updated!');
          window.location.href = '/';
        }
      } catch (err) {
        console.error('Error updating itinerary:', err);
        alert('An error occurred while updating the itinerary.');
      }
    } else {
      alert('Please fill in all the required fields.');
    }
  }

  return (
    <>
      <div className='row'>
        <div className='column-layout-left'>
          <SideBar />
        </div>
        <div className='column-layout-middle'>
          <TopBar />
          <div className='signup'>
            <br />
            {user ? (
              <>
                <h2>Edit Itinerary</h2>
                <br /><br />
                <div>
                  <p>Title (Max 40 characters)</p>
                  <input
                    type="text"
                    id="title"
                    maxLength="40"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <br />
                <div>
                  <p>Short Description (Max 200 characters)</p>
                  <textarea
                    id="shortDescription"
                    rows="5"
                    maxLength="200"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                  ></textarea>
                </div>
                <br />
                <div>
                  <p>Currency</p>
                  <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
                    {worldCurrencies.map((currency, index) => (
                      <option key={index} value={index}>{`${currency.name} (${currency.symbol})`}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <p>Group Type</p>
                  <select value={groupType} onChange={(e) => setGroupType(e.target.value)}>
                    {groupType ? <option value={groupType.id}>{groupType.name}</option> : <option>Select Group Type</option>}
                    {groupTypes.map((groupType) => (
                      <option key={groupType.id} value={groupType.id}>{groupType.name}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <p>Category</p>
                  <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    {category ? <option value={category.id}>{category.name}</option> : <option>Select Category</option>}
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <p>Did you take a flight?</p>
                  <select value={tookFlight ? 'yes' : 'no'} onChange={(e) => setTookFlight(e.target.value === 'yes')}>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
                <br />
                {tookFlight ? (
                  <>
                    <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                      <h3>Departure Airport</h3>
                      <br />
                      <div>
                        <p>Search Departure Airport</p>
                        <input
                          type="text"
                          value={departureAirportInputValue}
                          onChange={(e) => setDepartureAirportInputValue(e.target.value)}
                        />
                        <br /><br />
                        <button onClick={() => handleAirportSearchButton(departureAirportInputValue, setDepartureAirportsFound, setDepartureAirport)}>Find Airports</button>
                        <br /><br />
                        <select onChange={(e) => handleSelectAirport(e.target.value, departureAirportsFound, setDepartureAirport)}>
                          <option>{departureAirport && departureAirport.display_name}</option>
                          {departureAirportsFound.map((airport, index) => (
                            <option key={index} value={index}>{airport.display_name}</option>
                          ))}
                        </select>
                        <br /><br />
                        {departureAirport && <p style={{ margin: '0 25% 0 25%' }}><b>Selected</b>: {departureAirport.display_name.split(',')[0]}</p>}
                      </div>
                      <br />
                      <div>
                        <p>Departure Flight Cost</p>
                        <input
                          type="number"
                          id="departureFlightCost"
                          value={departureFlightCost}
                          onChange={(e) => setDepartureFlightCost(e.target.value)}
                        />
                      </div>
                    </div>
                    <br /><br />
                    <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                      <h3>Return Airport</h3>
                      <br />
                      <div>
                        <p>Search Return Airport</p>
                        <input
                          type="text"
                          value={returnAirportInputValue}
                          onChange={(e) => setReturnAirportInputValue(e.target.value)}
                        />
                        <br /><br />
                        <button onClick={() => handleAirportSearchButton(returnAirportInputValue, setReturnAirportsFound, setReturnAirport)}>Find Airports</button>
                        <br /><br />
                        <select onChange={(e) => handleSelectAirport(e.target.value, returnAirportsFound, setReturnAirport)}>
                          <option>{returnAirport && returnAirport.display_name}</option>
                          {returnAirportsFound.map((airport, index) => (
                            <option key={index} value={index}>{airport.display_name}</option>
                          ))}
                        </select>
                        <br /><br />
                        {returnAirport && <p style={{ margin: '0 25% 0 25%' }}><b>Selected</b>: {returnAirport.display_name.split(',')[0]}</p>}
                      </div>
                      <br />
                      <div>
                        <p>Return Flight Cost</p>
                        <input
                          type="number"
                          id="returnFlightCost"
                          value={returnFlightCost}
                          onChange={(e) => setReturnFlightCost(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                      <h3>Destination Country</h3>
                      <br />
                      <p>Search Destination Country</p>
                      <input
                        type="text"
                        id="destinationCountry"
                        placeholder="Insert destination country..." 
                        value={destinationCountryInputValue}
                        onChange={(e) => setDestinationCountryInputValue(e.target.value)}
                      />
                      <br /><br />
                      <button onClick={() => handleCountrySearch(destinationCountryInputValue)}>Find Countries</button>
                      <br /><br />
                      <p>Select Destination Country</p>
                      <select 
                        value={destinationCountry ? destinationCountry.display_name : ''}
                        onChange={(e) => setDestinationCountry(destinationCountriesFound[e.target.value])}>
                        <option selected disabled>Select destination country</option>
                        {destinationCountriesFound.map((country, index) => (
                          <option key={index} value={index}>{country.display_name}</option>
                        ))}
                      </select>
                    </div>
                    <br /><br />
                  </>
                )}
                <br /><br />
                <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                  <h3>Participants</h3>
                  <br />
                  <label>Adults
                    <input
                      type="number"
                      id="adults"
                      value={adult}
                      onChange={(e) => setAdult(e.target.value)}
                    />
                  </label>
                  <br /><br />
                  <label>Children
                    <input
                      type="number"
                      id="children"
                      value={children}
                      onChange={(e) => setChildren(e.target.value)}
                    />
                  </label>
                </div>
                <br /><br />
                <div>
                  <p>Cover Image</p>
                  <input
                    type="file"
                    id="coverImage"
                    accept="image/*"
                    onChange={(e) => setCoverImage(e.target.files[0])}
                  />
                </div>
                <br />
                <div>
                  <p>Visibility</p>
                  <select value={visibility} onChange={(e) => setVisibility(e.target.value === 'true')}>
                    <option value="true">Public</option>
                    <option value="false">Private</option>
                  </select>
                </div>
                <br />
                <button onClick={handleUpdate}>Update</button>
                <br /><br />
              </>
            ) : (
              <p>Please log in to edit your itinerary.</p>
            )}
          </div>
        </div>
        <BottomNav />
      </div>
      <Footer />
    </>
  );
}

export default EditItinerary;