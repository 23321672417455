import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { db, auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { collection, getDoc, getDocs, doc, updateDoc } from 'firebase/firestore';

import { useAuth } from '../components/AuthContext';

import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';
import BottomNav from '../components/BottomNav';

import NotFound from './NotFound';

import '../styles/profile.css';

function Profile() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();

  // Get the username from URL parameters
  const { username } = useParams();

  const [userData, setUserData] = useState();
  const [publicUserData, setPublicUserData] = useState();
  const [loadingPublicUserData, setLoadingPublicUserData] = useState(true);
  const [itinerariesData, setItinerariesData] = useState(true);
  const [loadingItinerariesData, setLoadingItinerariesData] = useState(true);

  const [userFollowing, setUserFollowing] = useState();
  const [publicUserFollowers, setPublicUserFollowers] = useState();
  const [isFollowing, setIsFollowing] = useState();

  const usersCollectionRef = collection(db, "users");
  const itinerariesCollectionRef = collection(db, "itineraries");

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          // Read user data from the database
          const usersSnapshot = await getDocs(usersCollectionRef);

          // Find the document corresponding to the current user
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);

          if (filteredUserDoc) {
            // Extract user data from the document
            const userData = {
              id: filteredUserDoc.id,
              ...filteredUserDoc.data()
            };
            setUserData(userData);
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [user]);  

  useEffect(() => {
    const fetchData = async () => {
      setLoadingItinerariesData(true);
      if (publicUserData) {
        try {
          const itinerariesSnapshot = await getDocs(itinerariesCollectionRef);
          const itData = [];

          itinerariesSnapshot.forEach((itinerary) => {
            const data = { id: itinerary.id, ...itinerary.data() };
            // If itineraries of user profile page, add them
            if(data.user.id === publicUserData.id)
              itData.push(data);
          });

          setItinerariesData(itData);
          setLoadingItinerariesData(false);
        } catch (error) {
          setLoadingItinerariesData(false);
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [publicUserData]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingPublicUserData(true);
        // Read user data from the database
        const usersSnapshot = await getDocs(usersCollectionRef);

        // Find the document corresponding to the current user
        const filteredUserDoc = usersSnapshot.docs.find(doc => doc.data().username === username);

        if (filteredUserDoc) {
          // Extract user data from the document
          const pUserData = {
            id: filteredUserDoc.id,
            ...filteredUserDoc.data()
          };
          setPublicUserData(pUserData);
          setLoadingPublicUserData(false);
        } else {
          setLoadingPublicUserData(false);
          console.error('User document not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoadingPublicUserData(false); // Reset loading state in both success and error cases
      }
    };

    fetchData();
  }, [username]);

  // Handle followers count
  useEffect(() => {
    const fetchData = async () => {
      if (userData && publicUserData) {
        try {
          // Current user ref, logged-in user
          const userDocRef = doc(db, 'users', userData.id);
          // Public user ref, user profile being explored
          const publicUserDocRef = doc(db, 'users', publicUserData.id);

          // Get current following and followers arrays
          const userDocSnapshot = await getDoc(userDocRef);
          const publicUserDocSnapshot = await getDoc(publicUserDocRef);

          // Update following and followers arrays
          const prevUserFollowing = userDocSnapshot.data().following || [];
          const prevPublicUserFollowers = publicUserDocSnapshot.data().followers || [];

          // Check if the current user is already following the public user
          const isFollowing = prevUserFollowing.some(doc => doc.id === publicUserDocRef.id);

          setUserFollowing(prevUserFollowing);
          setPublicUserFollowers(prevPublicUserFollowers);
          setIsFollowing(isFollowing);
        } catch (error) {
          console.error('Error fetching follower data:', error);
        }
      } else if (publicUserData) {
        // Public user ref, user profile being explored
        const publicUserDocRef = doc(db, 'users', publicUserData.id);
        // Get current followers arrays
        const publicUserDocSnapshot = await getDoc(publicUserDocRef);
        // Update following and followers arrays
        const prevPublicUserFollowers = publicUserDocSnapshot.data().followers || [];
        setPublicUserFollowers(prevPublicUserFollowers);
      }
    };

    fetchData();
  }, [userData, publicUserData]);

  const handleFollow = async () => {
    try {
      // Current user ref, logged-in user
      const userDocRef = doc(db, 'users', userData.id);
      // Public user ref, user profile being explored
      const publicUserDocRef = doc(db, 'users', publicUserData.id);

      // Check if the current user is already following the public user
      const isFollowing = userFollowing.some(doc => doc.id === publicUserDocRef.id);

      if (isFollowing) {
        // If already following, unfollow
        const updatedFollowing = userFollowing.filter(ref => ref.id !== publicUserData.id);

        await updateDoc(userDocRef, {
          following: updatedFollowing
        });

        const updatedFollowers = publicUserFollowers.filter(ref => ref.id !== userData.id);

        await updateDoc(publicUserDocRef, {
          followers: updatedFollowers
        });

        // Update userFollowing and publicUserFollowers states
        setUserFollowing(updatedFollowing);
        setPublicUserFollowers(updatedFollowers);

        setIsFollowing(!isFollowing);
      } else {
        // If not following, follow
        const updatedFollowing = [...userFollowing, publicUserDocRef];

        await updateDoc(userDocRef, {
          following: [...userFollowing, publicUserDocRef]
        });

        const updatedFollowers = [...publicUserFollowers, userDocRef];

        await updateDoc(publicUserDocRef, {
          followers: [...publicUserFollowers, userDocRef]
        });

        // Update userFollowing and publicUserFollowers states
        setUserFollowing(updatedFollowing);
        setPublicUserFollowers(updatedFollowers);

        setIsFollowing(!isFollowing);
      }
    } catch (err) {
      console.error(err);
    }
  };  

  const logout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/';
    } catch (err) {
      console.error(err);
    };
  };

  // If publicUserData is null or undefined, render the NotFound component
  if (!loadingPublicUserData && !publicUserData) {
    return <NotFound />;
  }

  return (
    <div className='row'>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle'>
        <TopBar />
        {loadingPublicUserData ? (
          <div className="loading-animation"></div>
        ) : publicUserData ? (
          <div className='profile'>
            <div className='info-container'>
              <div className='image-container'>
                <img src='https://images.pexels.com/photos/4553618/pexels-photo-4553618.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
              </div>
              <div className="username">
                <h3 style={{ display: 'inline' }}>{publicUserData.username}</h3>{" "}
                {/* If user is not owner of profile */}
                {userData && userData.id != publicUserData.id ? (
                  !isFollowing ? (
                    <button style={{ display: 'inline' }} onClick={() => handleFollow()}>Follow +</button>
                  ) : (
                    <button style={{ display: 'inline', backgroundColor: 'gray' }} onClick={() => handleFollow()}>Unfollow -</button>
                  )
                ) : userData && (
                  <>
                    {/* <a href={`/`} style={{ display: 'inline' }}><button>Edit</button></a>{' '} */}
                    <button style={{ display: 'inline' }} onClick={logout}>Logout</button>
                  </>
                )}
                <h3>{}</h3>
                <br />
                <div className="info">
                  <div>
                    <p><b>{itinerariesData.length}</b> <span>Itineraries</span></p>
                  </div>
                  <div>
                    <p><b>{publicUserFollowers && publicUserFollowers.length}</b> <span>Followers</span></p>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
            <div className='row'>
              {!loadingItinerariesData ? (
                /* If user is not owner of profile load only public data */
                userData && userData.id != publicUserData.id ? (
                  itinerariesData.filter(itinerary => itinerary.visibility).map(itinerary => (
                    <>
                      <div className='column-itinerary'>
                        <div className='square-img'>
                          <a href={`/itinerary/${itinerary.id}`}><img key={itinerary.id} src={itinerary.coverImage} alt={itinerary.title} /></a>
                          <div className="image-text">
                            <p>{itinerary.title}</p>
                          </div>
                          <div className="price-tag">{itinerary.currency.symbol + itinerary.budget}</div>
                          {!itinerary.visibility && <div className="visibility-tag">Only visible to you</div>}
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  itinerariesData.map(itinerary => (
                    <>
                      <div className='column-itinerary'>
                        <div className='square-img'>
                          <a href={`/itinerary/${itinerary.id}`}><img key={itinerary.id} src={itinerary.coverImage} alt={itinerary.title} /></a>
                          <div className="image-text">
                            <p>{itinerary.title}</p>
                          </div>
                          <div className="price-tag">{itinerary.currency.symbol + itinerary.budget}</div>
                          {!itinerary.visibility && <div className="visibility-tag">Only visible to you</div>}
                        </div>
                      </div>
                    </>
                  ))
                )
              ) : (
                <div className="loading-animation"></div>
              )}
              <br />
            </div>
          </div>
        ) : (
          <p>User not found</p>
        )}
        <BottomNav />
      </div>
      <div className='column-layout-right'>

      </div>
    </div>
  )
}

export default Profile;