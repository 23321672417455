import React, { useState, useEffect } from 'react';

import '../styles/style.css';
import '../styles/layout.css';
import '../styles/loadinganimation.css';
import '../styles/components/topbar.css';

function TopBar() {
  return (
    <div className='top-bar'>
      <a href='/'><img src='../../../itinerify.png' /></a>
    </div>
  )
}

export default TopBar;