import React from 'react';

import '../styles/components/footer.css'

function Footer() {
  return (
    <footer>
      <div>
        <p>© 2024 Itinerify. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;