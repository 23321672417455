import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyB83uu77UwzRvJzYxG3D0hZb_-7fZLprhU",
  authDomain: "itinerify-77705.firebaseapp.com",
  projectId: "itinerify-77705",
  storageBucket: "itinerify-77705.appspot.com",
  messagingSenderId: "308615577019",
  appId: "1:308615577019:web:c327f04f2983f560c0189e"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const GoogleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);

export const storage = getStorage(app);

export const perf = getPerformance(app);