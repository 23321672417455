import React, { useState, useEffect } from 'react';

import { db, auth, GoogleProvider } from '../config/firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { collection, setDoc, doc, getDocs, where } from 'firebase/firestore';

import { useAuth } from '../components/AuthContext';

import SideBar from '../components/SideBar';

import '../styles/signup.css';

function Signup() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();

  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [usernameExists, setUsernameExists] = useState();
  
  const usersCollectionRef = collection(db, "users");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all documents in the users collection
        const querySnapshot = await getDocs(usersCollectionRef);
        // Array to store user data
        const userData = [];
        
        // Map through users snapshot and push data to userData array
        querySnapshot.forEach((doc) => {
          const user = doc.data();
          userData.push(user);
        });
  
        // Check if username exists in userData
        const exists = userData.some(user => user.username === username);
        setUsernameExists(exists);
        
        // Now you can use usernameExists to determine if the username already exists
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [username, usersCollectionRef]);

  const signUp = async () => {
    try {
      if(password === repeatPassword) {
        if(email != null) {
          if(!usernameExists) {
            await createUserWithEmailAndPassword(auth, email, password).then(cred => {
              return setDoc(doc(usersCollectionRef, cred.user.uid), {
                email: email,
                username: username,
                registrationDate: new Date()
              });
            });
            window.location.href = '/';
          } else {
            alert(`Username already in use`)
          }
        } else {
          alert(`Please insert your email`)
        }
      } else {
        alert(`Passwords don't match`)
      }
    } catch (err) {
      if (err.code === 'auth/invalid-email') {
        alert('Invalid email.');
      } else if (err.code === 'auth/missing-email') {
        alert('Missing email.');
      } else if (err.code === 'auth/missing-password') {
        alert('Missing password.');
      } else if (err.code === 'auth/weak-password') {
        alert('Weak password, should be at least 6 characters.');
      } else if (err.code === 'auth/email-already-in-use') {
        alert('Email already in use.');
      } else {
        console.log(err.code)
        console.log('Error signing in:', err.message);
      }
    };
  };

  const signUpWithGoogle = async () => {
    try {
      await signInWithPopup(auth, GoogleProvider).then(async cred => {
        const userDocRef = doc(usersCollectionRef, cred.user.uid);
        return await setDoc(userDocRef, {
          email: cred.user.email,
          username: cred.user.email.split('@')[0] + Date.now(),
          registrationDate: new Date()
        })
      });
      window.location.href = '/';
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='row'>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle'>
        {!user ? (
          <div className='signup'>
            <img src='itinerify.png' />
            <br /><br />
            <p>You can use your email or your Google account</p>
            <br />
            <button onClick={signUpWithGoogle}>Login with Google</button>
            <br /><br />
            <div>
              <p>E-mail</p>
              <input
                type="email"
                id="email"
                placeholder="E-mail..." 
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <br />
            <div>
              <p>Username</p>
              <input
                type="text"
                id="username"
                placeholder="Username..." 
                onChange={(e) => setUsername(e.target.value)}
              />
              {usernameExists ? <p style={{ color: 'red', fontSize: '15px' }}>Username already in use</p> : null}
            </div>
            <br />
            <div>
              <p>Password</p>
              <input
                type="password"
                id="password"
                placeholder="Password..." 
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <br />
            <div>
              <p>Repeat Password</p>
              <input
                type="password"
                id="repeatPassword"
                placeholder="Repeat password..." 
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
            </div>
            <br />
            <button onClick={signUp}>Signup</button>
            <br /><br />
            <button onClick={() => window.location.href = '/login'}>Already have an account? Login!</button>
          </div>
        ) : <p>User already logged in</p>}
      </div>
      <div className='column-layout-right'>

      </div>
    </div>
  )
}

export default Signup;