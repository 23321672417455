import React, { useState, useEffect } from 'react';

import { db, auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';

import { useAuth } from '../components/AuthContext';

import '@fontsource/roboto';

import '../styles/style.css';
import '../styles/layout.css';
import '../styles/loadinganimation.css';
import '../styles/components/sidebar.css';

function SideBar() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();

  const [userData, setUserData] = useState();
  // State for dropdown visibility
  const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);

  const usersCollectionRef = collection(db, "users");

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          // Read user data from the database
          const usersSnapshot = await getDocs(usersCollectionRef);

          // Find the document corresponding to the current user
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);

          if (filteredUserDoc) {
            // Extract user data from the document
            const userData = filteredUserDoc.data();
            setUserData(userData);
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [user]);  

  const logout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/';
    } catch (err) {
      console.error(err);
    };
  };

  return (
    <div className='sidebar'>
      <br />
      <a href='/'><img src='../../../itinerify.png' /></a>
      <br /><br />
      {!userData ? <a href='/login'><p>🔑 Login</p></a> : <a href={`/${userData.username}`}><p>👤 Profile</p></a>}
      <p style={{ cursor: 'pointer' }} onClick={() => setIsCreateDropdownOpen(!isCreateDropdownOpen)}>➕ Create</p>
      {isCreateDropdownOpen && (
        <div className='dropdown'>
          <a href='/itinerary/new'><p>🚐 Itinerary</p></a>
        </div>
      )}
      <a href='/search'><p>🔍 Search</p></a>
      <a href='/privacy-policy'><p>📖 Privacy Policy</p></a>
      {user ? <p onClick={logout} style={{ cursor: 'pointer' }}>Logout</p> : null}
    </div>
  )
}

export default SideBar;