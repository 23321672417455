import React, { useState, useEffect } from 'react';

import { db, auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';

import { useAuth } from './AuthContext';

import '@fontsource/roboto';

import '../styles/style.css';
import '../styles/layout.css';
import '../styles/loadinganimation.css';
import '../styles/components/bottomnav.css';

function BottomNav() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();

  const [userData, setUserData] = useState();
  // State for dropdown visibility
  const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);

  const usersCollectionRef = collection(db, "users");

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          // Read user data from the database
          const usersSnapshot = await getDocs(usersCollectionRef);

          // Find the document corresponding to the current user
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);

          if (filteredUserDoc) {
            // Extract user data from the document
            const userData = filteredUserDoc.data();
            setUserData(userData);
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [user]);  

  return (
    <div className='bottom-nav'>
      <div className="nav-items">
        <a href='/'><p>🏠</p></a>
        <a href='/search'><p>🔍</p></a>
        <p style={{ cursor: 'pointer' }} onClick={() => setIsCreateDropdownOpen(!isCreateDropdownOpen)}>➕</p>
        {isCreateDropdownOpen && (
          <div className='dropdown'>
            <a href='/itinerary/new'><p>🚐 Itinerary</p></a>
          </div>
        )}
        {!userData ? <a href='/login'><p>👤</p></a> : <a href={`/${userData.username}`}><p>👤</p></a>}
      </div>
    </div>
  )
}

export default BottomNav;