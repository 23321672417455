import React, { useState, useEffect } from 'react';
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useParams } from 'react-router-dom';
import { db } from '../../config/firebase';
import { collection, getDoc, getDocs, doc, addDoc, where, query, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../components/AuthContext';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import BottomNav from '../../components/BottomNav';
import NotFound from '../NotFound';
import "leaflet/dist/leaflet.css";
import '../../styles/itinerary.css';
import '../../styles/newdate.css';

function NewDay() {
  const { user } = useAuth();
  const { itineraryId } = useParams();

  const [userData, setUserData] = useState();
  const [activities, setActivities] = useState();
  const [accommodationTypes, setAccommodationTypes] = useState();
  const [itineraryData, setItineraryData] = useState();
  const [loadingItineraryData, setLoadingItineraryData] = useState(true);

  const [date, setDate] = useState();
  const [title, setTitle] = useState('');
  const [accommodationType, setAccommodationType] = useState();
  const [accommodationCost, setAccommodationCost] = useState(0);
  const [accommodationInputValue, setAccommodationInputValue] = useState('');
  const [accommodationsFound, setAccommodationsFound] = useState([]);
  const [accommodation, setAccommodation] = useState();

  const [inputValue, setInputValue] = useState('');
  const [placesFound, setPlacesFound] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState();
  const [placesVisited, setPlacesVisited] = useState([]);
  const [activity, setActivity] = useState();
  const [activityPrice, setActivityPrice] = useState(0);
  const [showExpensePopup, setShowExpensePopup] = useState(false);
  const [expenseDescription, setExpenseDescription] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenses, setExpenses] = useState([]);
  const [rating, setRating] = useState(1);

  const [mapCenter, setMapCenter] = useState([45.723, -38.500]);
  const [mapZoom, setMapZoom] = useState(2);

  const usersCollectionRef = collection(db, "users");
  const activitiesCollectionRef = collection(db, "activities");
  const accommodationTypesCollectionRef = collection(db, "accommodationTypes");
  const daysCollectionRef = collection(db, "days");

  const customIcon = new L.Icon({
    iconUrl: '../../../map-pin-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
  });

  const customAccommodationIcon = new L.Icon({
    iconUrl: '../../../map-pin-acc-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
  });

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const usersSnapshot = await getDocs(usersCollectionRef);
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);

          if (filteredUserDoc) {
            const userData = {
              id: filteredUserDoc.id,
              ...filteredUserDoc.data()
            };
            setUserData(userData);
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read activities data from the database
        const activitiesSnapshot = await getDocs(activitiesCollectionRef);
        const activities = activitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setActivities(activities);
        setActivity(activities[0].id)
      } catch (error) {
        console.error('Error fetching activities data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read accommodation types data from the database
        const accommodationTypesSnapshot = await getDocs(accommodationTypesCollectionRef);
        const accommodationTypes = accommodationTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAccommodationTypes(accommodationTypes);
        setAccommodationType(accommodationTypes[0].id)
      } catch (error) {
        console.error('Error fetching accommodation types data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingItineraryData(true);
      if (itineraryId) {
        try {
          const itineraryDocRef = doc(db, 'itineraries', itineraryId);
          const itineraryDocSnapshot = await getDoc(itineraryDocRef);

          if (itineraryDocSnapshot.exists()) {
            setItineraryData({ id: itineraryDocSnapshot.id, ...itineraryDocSnapshot.data() });
          } else {
            console.log('No such document!');
          }

          setLoadingItineraryData(false);
        } catch (error) {
          setLoadingItineraryData(false);
          console.error('Error fetching itinerary data:', error);
        }
      }
    };

    fetchData();
  }, [itineraryId]);

  async function getAccommodations(input) {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json`);
      setAccommodationsFound(response.data[0]);
      setAccommodation(response.data[0]);
      return response.data;
    } catch (error) {
      console.error('Error fetching places:', error);
      return [];
    }
  };

  const handleAccommodationInputChange = (e) => {
    setAccommodationInputValue(e.target.value);
  };

  const handleAccommodationSearchButton = () => {
    if (accommodationInputValue) {
      getAccommodations(accommodationInputValue).then(accommodations => {
        if (accommodations.length > 0) {
          setAccommodationsFound(accommodations);
        }
        else {
          alert("No accommodations found!");
        }
      });
    } else {
      alert("Empty input!");
    }
  };

  const handleSelectAccommodation = (selectedAccommodation) => {
    setAccommodation(accommodationsFound[selectedAccommodation]);
  };

  async function getPlaces(input) {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json`);
      setSelectedPlace(response.data[0]);
      return response.data;
    } catch (error) {
      console.error('Error fetching places:', error);
      return [];
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearchButton = () => {
    if (inputValue) {
      getPlaces(inputValue).then(places => {
        if (places.length > 0)
          setPlacesFound(places);
        else {
          alert("No places found!");
        }
      });
    } else {
      alert("Empty input!");
    }
  };

  const handleSelectPlace = (selectedPlace) => {
    setSelectedPlace(placesFound[selectedPlace]);
  };

  const handleAddPlaceVisited = () => {
    if (selectedPlace) {
      // Check if selectedPlace.display_name is already present in placesVisited
      if (placesVisited.some(place => place.display_name === selectedPlace.display_name)) {
        alert("This place is already in your visited places list.");
        return;
      }

      // If not already present, add selectedPlace to placesVisited
      setPlacesVisited([
        ...placesVisited,
        {
          ...selectedPlace,
          activity: doc(db, 'activities', activity),
          activityPrice: activityPrice,
          expenses: expenses,
          rating: rating
        }
      ]);
      setInputValue('');
      setPlacesFound([]);
      setActivity(activities[0].id);
      setActivityPrice(0);
      setExpenses([]);
      setRating(1);
      setMapCenter([parseFloat(selectedPlace.lat), parseFloat(selectedPlace.lon)]);
      setMapZoom(11);
      setSelectedPlace(null);
    } else {
      alert("You must insert all data!");
    }
  };

  const handleRemovePlace = (index) => {
    const updatedPlacesVisited = [...placesVisited];
    updatedPlacesVisited.splice(index, 1);
    setPlacesVisited(updatedPlacesVisited);
  };

  const handleRemoveExpense = (index) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  };

  const toggleExpensePopup = () => {
    setShowExpensePopup(!showExpensePopup);
  };

  const handleExpenseDescriptionChange = (e) => {
    setExpenseDescription(e.target.value);
  };

  const handleExpenseAmountChange = (e) => {
    setExpenseAmount(e.target.value);
  };

  const handleExpenseSubmit = (e) => {
    e.preventDefault();
    // Add expense to the array of expenses
    setExpenses([...expenses, { description: expenseDescription, amount: expenseAmount }]);
    // Reset the fields
    setExpenseDescription('');
    setExpenseAmount('');
    // Close the popup
    toggleExpensePopup();
  };

  const handleCreateDay = async () => {
    if (placesVisited.length > 0) {
      if (date && title && accommodationType) {
        // Extract year, month, and day from the date string
        const [year, month, day] = date.split('-').map(Number);

        // Construct the Date object
        const selectedDate = new Date(year, month - 1, day);

        try {
          // Check if a day with the same date already exists
          const querySnapshot = await getDocs(query(daysCollectionRef, where('itinerary', '==', doc(db, 'itineraries', itineraryId)), where('date', '==', selectedDate)));
          if (!querySnapshot.empty) {
            alert('A day with the same date already exists for this itinerary.');
            return;
          }

          // If no existing day found, proceed to add the new day
          const newDayData = {
            user: doc(db, 'users', userData.id),
            itinerary: doc(db, 'itineraries', itineraryId),
            date: selectedDate,
            title: title,
            places: placesVisited,
            creationDate: new Date()
          };

          if (accommodationType !== "No accommodation") {
            newDayData.accommodation = {
              type: doc(db, 'accommodationTypes', accommodationType),
              cost: parseFloat(accommodationCost),
              place: accommodation
            };
          }

          await addDoc(daysCollectionRef, newDayData);

          // Fetch all days for the itinerary
          const daysSnapshot = await getDocs(query(daysCollectionRef, where('itinerary', '==', doc(db, 'itineraries', itineraryId))));
          let totalBudget = 0;
          let accommodationsBudget = 0;
          let activitiesBudget = 0;
          let otherExpensesBudget = 0;

          daysSnapshot.forEach(dayDoc => {
            const dayData = dayDoc.data();
            if (dayData.accommodation) {
              accommodationsBudget += parseFloat(dayData.accommodation.cost);
            }

            dayData.places.forEach(place => {
              activitiesBudget += parseFloat(place.activityPrice);
              place.expenses.forEach(expense => {
                otherExpensesBudget += parseFloat(expense.amount);
              });
            });
          });

          // Update the itinerary document with the calculated budget
          const itineraryDocRef = doc(db, 'itineraries', itineraryId);
          const itinerarySnapshot = await getDoc(itineraryDocRef);
          const itineraryData = itinerarySnapshot.data();

          totalBudget = accommodationsBudget + activitiesBudget + otherExpensesBudget + parseFloat(itineraryData.departureFlightCost) + parseFloat(itineraryData.returnFlightCost);

          await updateDoc(itineraryDocRef, { budget: totalBudget, accommodationsBudget, activitiesBudget, otherExpensesBudget, nDays: daysSnapshot.docs.length });

          alert('Day created and budget updated!');
          window.location.href = `/itinerary/${itineraryId}`;
        } catch (err) {
          console.error(err);
        }
      } else {
        alert('Date, title, or accommodation type missing!')
      }
    } else {
      alert('You need to add at least a place to your itinerary day!')
    }
  };

  return (
    <div className='row'>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle'>
        <TopBar />
        <div className='signup'>
          {userData && !loadingItineraryData ? (
            <>
              {userData.id === itineraryData.user.id ? (
                <>
                  <h2>Add a Day to the Itinerary</h2>
                  <br /><br />
                  <div>
                    <p>Date</p>
                    <input
                      type="date"
                      id="date"
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <br />
                  <div>
                    <p>Title</p>
                    <input
                      type="text"
                      id="title"
                      placeholder="Insert day title..."
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  </div>
                  <br />
                  <p>Select accommodation type</p>
                  <select
                    id="accommodationType"
                    value={accommodationType}
                    onChange={(e) => setAccommodationType(e.target.value)}
                  >
                    <option value="No accommodation">No accommodation</option>
                    {accommodationTypes.map((accommodation, index) => (
                      <option key={index} value={accommodation.id}>{accommodation.name}</option>
                    ))}
                  </select>
                  {accommodationType !== "No accommodation" && (
                    <>
                      <div>
                        <br />
                        <p>Accommodation cost</p>
                        <input
                          type="number"
                          id="accommodationCost"
                          placeholder="Insert hotel cost..."
                          onChange={(e) => setAccommodationCost(e.target.value)}
                          value={accommodationCost}
                        />
                      </div>
                      <br />
                      <p>Search accommodation</p>
                      <input
                        type="text"
                        id="accommodation"
                        placeholder="Insert accommodation..."
                        onChange={handleAccommodationInputChange}
                        value={accommodationInputValue}
                      />
                      <br /><br />
                      <button onClick={handleAccommodationSearchButton}>Search</button>
                      <br /><br />
                      <p>Select accommodation</p>
                      <select onChange={(e) => handleSelectAccommodation(e.target.value)}>
                        {accommodationsFound && accommodationsFound.map((accommodation, index) => (
                          <>
                            <option key={index} value={index}>{accommodation.display_name}</option>
                          </>
                        ))}
                      </select>
                    </>
                  )}
                  <br /><br /><br />
                  <hr />
                  <br /><br />
                  <div>
                    <div className='newdate-left-column'>
                      <p>Search visited place</p>
                      <input
                        type="text"
                        id="placesVisited"
                        placeholder="Insert place visited..."
                        onChange={handleInputChange}
                        value={inputValue}
                      />
                      <br /><br />
                      <button onClick={handleSearchButton}>Search</button>
                      <br /><br />
                      <p>Select place</p>
                      <select onChange={(e) => handleSelectPlace(e.target.value)}>
                        {placesFound.map((place, index) => (
                          <option key={index} value={index}>{place.display_name}</option>
                        ))}
                      </select>
                      <br /><br />
                      <p>What did you do here?</p>
                      <select
                        id="activity"
                        value={activity}
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        {activities.map((activity, index) => (
                          <option key={index} value={activity.id}>{activity.name}</option>
                        ))}
                      </select>
                      <br /><br />
                      <p>What's the activity price?</p>
                      <input
                        type="number"
                        id="expenses"
                        placeholder="How much did you spend here?"
                        onChange={(e) => setActivityPrice(e.target.value)}
                        value={activityPrice}
                      />
                      <br /><br />
                      <div style={{ border: '1px solid black', borderRadius: '10px' }}>
                        <br />
                        <p>Other Expenses</p>
                        <br />
                        <button onClick={toggleExpensePopup}>Add Expense</button>
                        {showExpensePopup && (
                          <div className="expense-popup">
                            <div className="expense-popup-inner">
                              <br />
                              <p>Description</p>
                              <input
                                type="text"
                                value={expenseDescription}
                                onChange={handleExpenseDescriptionChange}
                                style={{ width: '90%', margin: '0 5% 0 5%' }}
                              />
                              <br /><br />
                              <p>Amount Spent</p>
                              <input
                                type="number"
                                value={expenseAmount}
                                onChange={handleExpenseAmountChange}
                                style={{ width: '90%', margin: '0 5% 0 5%' }}
                              />
                              <br /><br />
                              <button onClick={handleExpenseSubmit}>Add</button>
                              <br /><br />
                              <button onClick={toggleExpensePopup}>Cancel</button>
                            </div>
                          </div>
                        )}
                        <div>
                          <br />
                          <h4>Expenses</h4>
                          <br />
                          {expenses.length > 0 ? (expenses.map((expense, index) => (
                            <div key={index} style={{ margin: '0 5% 0 5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <p>
                                {expense.description} | {itineraryData.currency.symbol}{expense.amount}
                              </p>
                              <button onClick={() => handleRemoveExpense(index)} style={{ marginLeft: '10px' }}>x</button>
                            </div>
                          ))) : (
                            <p>No expenses</p>
                          )}
                        </div>
                        <br />
                      </div>
                      <br />
                      <div>
                        <p>Rating</p>
                        <div>
                          {[...Array(5)].map((_, index) => (
                            <span
                              key={index}
                              style={{ cursor: 'pointer', color: index < rating ? 'gold' : 'gray', fontSize: '40px' }}
                              onClick={() => setRating(index + 1 || 1)}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                      <br />
                      <button onClick={handleAddPlaceVisited}>Add Place</button>
                      <br /><br />
                      <hr />
                      <br />
                      <p>When you've finished adding visited places please submit your Day</p>
                      <br />
                      <button onClick={handleCreateDay}>Submit Day</button>
                      <br /><br />
                    </div>
                    <div className='newdate-right-column'>
                      <MapContainer key={`${mapCenter[0]}-${mapCenter[1]}-${mapZoom}`} center={mapCenter} zoom={mapZoom} scrollWheelZoom={true} style={{ height: 500 }}>
                        <TileLayer
                          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {accommodation && (
                          <Marker position={[parseFloat(accommodation.lat), parseFloat(accommodation.lon)]} icon={customAccommodationIcon}>
                            <Popup>
                              <h4>{accommodation.name}</h4>
                              <p>{accommodation.type}</p>
                            </Popup>
                          </Marker>
                        )}
                        {placesVisited && placesVisited.map((place, index) => (
                          <Marker key={index} position={[parseFloat(place.lat), parseFloat(place.lon)]} icon={customIcon}>
                            <Popup>
                              <h4>{place.name}</h4>
                              <p>{activities.filter(act => act.id === place.activity.id)[0].name}</p>
                              <p>Expenses: {itineraryData.currency.symbol}{place.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)}</p>
                              <p>Rating: {Array.from({ length: 5 }, (_, index) => (index < place.rating ? <span className='review-star'>★</span> : '☆'))}</p>
                              <button onClick={() => handleRemovePlace(index)}>Remove</button>
                            </Popup>
                          </Marker>
                        ))}
                      </MapContainer>
                    </div>
                    <br /><br />
                    <div style={{ textAlign: 'left' }}>
                      {placesVisited.map((place, index) => (
                        <p key={index}>
                          <b>{index + 1}. </b>
                          {place.name !== '' ? place.name : place.display_name}
                        </p>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <NotFound />
              )}
            </>
          ) : (
            <div className="loading-animation"></div>
          )}
        </div>
        <BottomNav />
      </div>
      <div className='column-layout-right'>

      </div>
    </div>
  )
}

export default NewDay;
