import React, { useState, useEffect } from 'react';

import axios from "axios";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import { useParams } from 'react-router-dom';

import { db } from '../../config/firebase';
import { collection, getDoc, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';

import { useAuth } from '../../components/AuthContext';

import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import BottomNav from '../../components/BottomNav';

import NotFound from '../NotFound';

import "leaflet/dist/leaflet.css";

import '../../styles/itinerary.css';
import '../../styles/newdate.css';

const formatDate = (date) => {
  // Ensure date is in the format yyyy-MM-dd
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function EditDay() {
  const { user } = useAuth();
  const { dayId } = useParams();

  const [userData, setUserData] = useState();
  const [activities, setActivities] = useState();
  const [accommodationTypes, setaccommodationTypes] = useState();
  const [dayData, setDayData] = useState();
  const [loadingDayData, setLoadingDayData] = useState(true);

  const [date, setDate] = useState(null);
  const [title, setTitle] = useState('');
  const [accommodationType, setaccommodationType] = useState();
  const [accommodationCost, setaccommodationCost] = useState(0);
  const [accommodationInputValue, setaccommodationInputValue] = useState('');
  const [accommodationsFound, setaccommodationsFound] = useState([]);
  const [accommodation, setaccommodation] = useState();

  const [inputValue, setInputValue] = useState('');
  const [placesFound, setPlacesFound] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState();
  const [placesVisited, setPlacesVisited] = useState([]);
  const [activity, setActivity] = useState();
  const [activityPrice, setActivityPrice] = useState(0);
  const [showExpensePopup, setShowExpensePopup] = useState(false);
  const [expenseDescription, setExpenseDescription] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenses, setExpenses] = useState([]);
  const [rating, setRating] = useState(1);

  const [mapCenter, setMapCenter] = useState([45.723, -38.500]);
  const [mapZoom, setMapZoom] = useState(2);

  const usersCollectionRef = collection(db, "users");
  const activitiesCollectionRef = collection(db, "activities");
  const accommodationTypesCollectionRef = collection(db, "accommodationTypes");
  const daysCollectionRef = collection(db, "days");

  const customIcon = new L.Icon({
    iconUrl: '../../../map-pin-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
  });

  const customAccommodationIcon = new L.Icon({
    iconUrl: '../../../map-pin-acc-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
  });

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const usersSnapshot = await getDocs(usersCollectionRef);
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);

          if (filteredUserDoc) {
            const userData = {
              id: filteredUserDoc.id,
              ...filteredUserDoc.data()
            };
            setUserData(userData);
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [user]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read activities data from the database
        const activitiesSnapshot = await getDocs(activitiesCollectionRef);
        const activities = activitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setActivities(activities);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read accommodation types data from the database
        const accommodationTypesSnapshot = await getDocs(accommodationTypesCollectionRef);
        const accommodationTypes = accommodationTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setaccommodationTypes(accommodationTypes);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);  

  useEffect(() => {
    const fetchData = async () => {
      setLoadingDayData(true);
      if (dayId) {
        try {
          const dayDocRef = doc(db, 'days', dayId);
          const dayDocSnapshot  = await getDoc(dayDocRef);

          if (dayDocSnapshot.exists()) {
            setDayData({id: dayDocSnapshot.id, ...dayDocSnapshot.data()});
          } else {
            console.log('No such document!');
          }

          const dayDocData = dayDocSnapshot.data();

          setTitle(dayDocData.title);
          setDate(dayDocData.date.toDate());
          setaccommodationType(dayDocData.accommodation?.type?.id || "No accommodation");
          setaccommodationCost(dayDocData.accommodation?.cost || 0);
          setaccommodationsFound(dayDocData.accommodation ? [dayDocData.accommodation.place] : []);
          setaccommodation(dayDocData.accommodation?.place || null);
          setPlacesVisited(dayDocData.places);
          setMapCenter([dayDocData.places[0].lat, dayDocData.places[0].lon]);
          setMapZoom(11);

          setLoadingDayData(false);
        } catch (error) {
          setLoadingDayData(false);
          console.error('Error fetching itinerary data:', error);
        }
      }
    };

    fetchData();
  }, [dayId]);  

  async function getaccommodations(input) {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json`);
      setaccommodationsFound(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching places:', error);
      return [];
    }
  };

  const handleaccommodationInputChange = (e) => {
    setaccommodationInputValue(e.target.value);
  };

  const handleaccommodationSearchButton = () => {
    if (accommodationInputValue) {
      getaccommodations(accommodationInputValue).then(accommodations => {
        if (accommodations.length > 0) {
          setaccommodationsFound(accommodations);
        }
        else {
          alert("No accommodations found!");
        }
      });
    } else {
      alert("Empty input!");
    }
  };

  const handleSelectaccommodation = (selectedaccommodation) => {
    setaccommodation(selectedaccommodation);
  };

  async function getPlaces(input) {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json`);
      setSelectedPlace(response.data[0]);
      return response.data;
    } catch (error) {
      console.error('Error fetching places:', error);
      return [];
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearchButton = () => {
    if (inputValue) {
      getPlaces(inputValue).then(places => {
        if (places.length > 0)
          setPlacesFound(places);
        else {
          alert("No places found!");
        }
      });
    } else {
      alert("Empty input!");
    }
  };

  const handleSelectPlace = (selectedPlace) => {
    setSelectedPlace(placesFound[selectedPlace]);
  };

  const handleAddPlaceVisited = () => {
    if (selectedPlace && activity != null && activityPrice != null && rating != null) {
      // Check if selectedPlace.display_name is already present in placesVisited
      if (placesVisited.some(place => place.display_name === selectedPlace.display_name)) {
        alert("This place is already in your visited places list.");
        return;
      }

      // If not already present, add selectedPlace to placesVisited
      setPlacesVisited([
        ...placesVisited, 
        { 
          ...selectedPlace,
          activity: doc(db, 'activities', activity),
          activityPrice: activityPrice,
          expenses: expenses,
          rating: rating
        }
      ]);
      setInputValue('');
      setPlacesFound([]);
      setActivity(activities[0].id);
      setActivityPrice(0);
      setExpenses([]);
      setRating(0);
      setMapCenter([parseFloat(selectedPlace.lat), parseFloat(selectedPlace.lon)]);
      setMapZoom(11);
      setSelectedPlace(null);
    } else {
      alert("You must insert all data!");
    }
  };

  const handleRemovePlace = (index) => {
    const updatedPlacesVisited = [...placesVisited];
    updatedPlacesVisited.splice(index, 1); 
    setPlacesVisited(updatedPlacesVisited); 
  };

  const handleRemoveExpense = (index) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  };
  

  const toggleExpensePopup = () => {
    setShowExpensePopup(!showExpensePopup);
  };

  const handleExpenseDescriptionChange = (e) => {
    setExpenseDescription(e.target.value);
  };

  const handleExpenseAmountChange = (e) => {
    setExpenseAmount(e.target.value);
  };

  const handleExpenseSubmit = (e) => {
    e.preventDefault();
    // Add expense to the array of expenses
    setExpenses([...expenses, { description: expenseDescription, amount: expenseAmount }]);
    // Reset the fields
    setExpenseDescription('');
    setExpenseAmount('');
    // Close the popup
    toggleExpensePopup();
  };

  const handleUpdateDay = async () => {
    if (placesVisited.length > 0) {
      if (title && accommodationType) {
        try {
          // Check if day exists
          const dayDocRef = doc(db, 'days', dayId);
          const dayDocSnapshot = await getDoc(dayDocRef);
          if (!dayDocSnapshot.exists()) {
            alert('Day document not found!');
            return;
          }

          const updatedDayData = {
            title: title,
            places: placesVisited
          };

          if (accommodationType !== "No accommodation") {
            updatedDayData.accommodation = {
              type: doc(db, 'accommodationTypes', accommodationType),
              cost: parseFloat(accommodationCost),
              place: accommodation
            };
          } else {
            updatedDayData.accommodation = null;
          }

          // Update the existing day document
          await updateDoc(dayDocRef, updatedDayData);

          // Fetch all days for the itinerary
          const daysSnapshot = await getDocs(query(daysCollectionRef, where('itinerary', '==', doc(db, 'itineraries', dayData.itinerary.id))));
          let totalBudget = 0;
          let accommodationsBudget = 0;
          let activitiesBudget = 0;
          let otherExpensesBudget = 0;

          daysSnapshot.forEach(doc => {
            const dayData = doc.data();
            if (dayData.accommodation) {
              accommodationsBudget += parseFloat(dayData.accommodation.cost);
            }

            dayData.places.forEach(place => {
              activitiesBudget += parseFloat(place.activityPrice);
              place.expenses.forEach(expense => {
                otherExpensesBudget += parseFloat(expense.amount);
              });
            });
          });

          // Update the itinerary document with the calculated budget
          const itineraryDocRef = doc(db, 'itineraries', dayData.itinerary.id);
          const itinerarySnapshot = await getDoc(itineraryDocRef);
          const itinerary = itinerarySnapshot.data();

          totalBudget = accommodationsBudget + activitiesBudget + otherExpensesBudget + parseFloat(itinerary.departureFlightCost) + parseFloat(itinerary.returnFlightCost);

          await updateDoc(itineraryDocRef, { budget: totalBudget, accommodationsBudget: accommodationsBudget, activitiesBudget: activitiesBudget, otherExpensesBudget, nDays: daysSnapshot.docs.length });

          alert('Day updated and budget recalculated!');
          window.location.href = `/itinerary/${dayData.itinerary.id}`;
        } catch (err) {
          console.error(err);
        }
      } else {
        alert('Title or accommodation type missing!');
      }
    } else {
      alert('You need to add at least a place to your itinerary day!');
    }
  };  

  return (
    <div className='row'>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle'>
        <TopBar />
        <div className='signup'>
          {userData && !loadingDayData ? (
            <>
              {userData.id === dayData.user.id ? (
                <>
                  <h2>Edit Day of the Itinerary</h2>
                  <br /><br />
                  <div>
                    <p>Date (Cannot be changed)</p>
                    <input
                      type="date"
                      id="date"
                      value={date ? formatDate(new Date(date)) : ''}
                      onChange={(e) => setDate(e.target.value)}
                      disabled
                    />
                  </div>
                  <br />
                  <div>
                    <p>Title</p>
                    <input
                      type="text"
                      id="title"
                      placeholder="Insert day title..." 
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  </div>
                  <br />
                  <p>Select accommodation type</p>
                  <select
                    id="accommodationType"
                    value={accommodationType}
                    onChange={(e) => setaccommodationType(e.target.value)}
                  >
                    <option value="No accommodation">No accommodation</option>
                    {accommodationTypes.map((accommodation, index) => (
                      <option key={index} value={accommodation.id}>{accommodation.name}</option>
                    ))}
                  </select>
                  {accommodationType !== "No accommodation" && (
                    <>
                      <div>
                        <br />
                        <p>Accommodation cost</p>
                        <input
                          type="number"
                          id="accommodationCost"
                          placeholder="Insert hotel cost..." 
                          onChange={(e) => setaccommodationCost(e.target.value)}
                          value={accommodationCost}
                        />
                      </div>
                      <br />
                      <p>Search accommodation</p>
                      <input
                        type="text"
                        id="accommodation"
                        placeholder="Insert accommodation..." 
                        onChange={handleaccommodationInputChange}
                        value={accommodationInputValue}
                      />
                      <br /><br />
                      <button onClick={handleaccommodationSearchButton}>Search</button>
                      <br /><br />
                      <p>Select accommodation</p>
                      <select onChange={(e) => handleSelectaccommodation(e.target.value)}>
                        {accommodationsFound && accommodationsFound.map((accommodation, index) => (
                          <option key={index} value={index}>{accommodation.display_name}</option>
                        ))}
                      </select>
                    </>
                  )}
                  <br /><br /><br />
                  <hr />
                  <br /><br />
                  <div>
                    <div className='newdate-left-column'>
                      <p>Search visited place</p>
                      <input
                        type="text"
                        id="placesVisited"
                        placeholder="Insert place visited..." 
                        onChange={handleInputChange}
                        value={inputValue}
                      />
                      <br /><br />
                      <button onClick={handleSearchButton}>Search</button>
                      <br /><br />
                      <p>Select place</p>
                      <select onChange={(e) => handleSelectPlace(e.target.value)}>
                        {placesFound.map((place, index) => (
                          <option key={index} value={index}>{place.display_name}</option>
                        ))}
                      </select>
                      <br /><br />
                      <p>What did you do here?</p>
                      <select
                        id="activity"
                        value={activity}
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        {activities.map((activity, index) => (
                          <option key={index} value={activity.id}>{activity.name}</option>
                        ))}
                      </select>
                      <br /><br />
                      <p>What's the activity price?</p>
                      <input
                        type="number"
                        id="expenses"
                        placeholder="How much did you spend here?" 
                        onChange={(e) => setActivityPrice(e.target.value)}
                        value={activityPrice}
                      />
                      <br /><br />
                      <div style={{ border: '1px solid black', borderRadius: '10px' }}>
                        <br />
                        <p>Other Expenses</p>
                        <br />
                        <button onClick={toggleExpensePopup}>Add Expense</button>
                        {showExpensePopup && (
                          <div className="expense-popup">
                            <div className="expense-popup-inner">
                              <br />
                              <p>Description</p>
                              <input
                                type="text"
                                value={expenseDescription}
                                onChange={handleExpenseDescriptionChange}
                                style={{ width: '90%', margin: '0 5% 0 5%' }}
                              />
                              <br /><br />
                              <p>Amount Spent</p>
                              <input
                                type="number"
                                value={expenseAmount}
                                onChange={handleExpenseAmountChange}
                                style={{ width: '90%', margin: '0 5% 0 5%' }}
                              />
                              <br /><br />
                              <button onClick={handleExpenseSubmit}>Add</button>
                              <br /><br />
                              <button onClick={toggleExpensePopup}>Cancel</button>
                            </div>
                          </div>
                        )}
                        <div>
                          <br />
                          <h4>Expenses</h4>
                          <br />
                          {expenses.length > 0 ? (
                            expenses.map((expense, index) => (
                              <div key={index} style={{ margin: '0 5% 0 5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p>
                                  {expense.description} | £{expense.amount}
                                </p>
                                <button onClick={() => handleRemoveExpense(index)} style={{ marginLeft: '10px' }}>x</button>
                              </div>
                            ))
                          ) : (
                            <p>No expenses</p>
                          )}
                        </div>
                        <br />
                      </div>
                      <br />
                      <div>
                        <p>Rating</p>
                        <div>
                          {[...Array(5)].map((_, index) => (
                            <span
                              key={index}
                              style={{ cursor: 'pointer', color: index < rating ? 'gold' : 'gray', fontSize: '40px' }}
                              onClick={() => setRating(index + 1)}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                      <br /><br />
                      <button onClick={handleAddPlaceVisited}>Add</button>
                    </div>
                    <div className='newdate-right-column'>
                      <MapContainer key={`${mapCenter[0]}-${mapCenter[1]}-${mapZoom}`} center={mapCenter} zoom={mapZoom} scrollWheelZoom={true} style={{ height: 500 }}>
                        <TileLayer
                          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {accommodation && (
                          <Marker position={[parseFloat(accommodation.lat), parseFloat(accommodation.lon)]} icon={customAccommodationIcon}>
                            <Popup>
                              <h4>{accommodation.name}</h4>
                              <p>{accommodation.type}</p>
                            </Popup>
                          </Marker>
                        )}
                        {placesVisited && placesVisited.map((place, index) => (
                          <Marker key={index} position={[parseFloat(place.lat), parseFloat(place.lon)]} icon={customIcon}>
                            <Popup>
                              <h4>{place.name != '' ? place.name : place.display_name.split(',')[0] + ', ' + place.display_name.split(',')[1]}</h4>
                              <p>{activities && activities.filter(act => act.id === place.activity.id)[0].name}</p>
                              <p>Expenses: £{place.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)}</p>
                              <p>Rating: { Array.from({ length: 5 }, (_, index) => ( index < place.rating ? <span className='review-star'>★</span> : '☆' )) }</p>
                              <button onClick={() => handleRemovePlace(index)}>Remove</button>
                            </Popup>
                          </Marker>
                        ))}
                      </MapContainer>
                    </div>
                    <br /><br />
                    <div style={{ textAlign: 'left' }}>
                      {placesVisited.map((place, index) => (
                        <p key={index}>
                        <b>{index + 1}. </b>
                        {place.name !== '' ? place.name : place.display_name.split(',')[0] + ', ' + place.display_name.split(',')[1]}
                      </p>
                      ))}
                    </div>
                  </div>
                  <br /><br />
                  <button onClick={handleUpdateDay}>Update Day</button>
                  <br /><br />
                </>
              ) : (
                <NotFound />
              )}
            </>
          ) : (
            <div className="loading-animation"></div>
          )}
        </div>
        <BottomNav />
      </div>
      <div className='column-layout-right'>

      </div>
    </div>
  )
}

export default EditDay;
