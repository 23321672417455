import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useAuth } from '../../components/AuthContext';
import { db } from '../../config/firebase';
import { collection, doc, getDocs, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import BottomNav from '../../components/BottomNav';
import Footer from '../../components/Footer';
import imageCompression from 'browser-image-compression';

const worldCurrencies = [
  { name: 'Euro', symbol: '€' }, { name: 'United States dollar', symbol: '$' }, 
  { name: 'Australian dollar', symbol: '$' }, { name: 'Sterling', symbol: '£' }, 
  { name: 'Eastern Caribbean dollar', symbol: '$' }, { name: 'West African CFA franc', symbol: 'Fr' },
  { name: 'New Zealand dollar', symbol: '$' }, { name: 'Norwegian krone', symbol: 'kr' }, 
  { name: 'Central African CFA franc', symbol: 'Fr' }, { name: 'South African rand', symbol: 'R' }, 
  { name: 'CFP franc', symbol: '₣' }, { name: 'Chilean peso', symbol: '$' }, 
  { name: 'Danish krone', symbol: 'kr' }, { name: 'Indian rupee', symbol: '₹' }, 
  { name: 'Turkish lira', symbol: '₺' }, { name: 'Algerian dinar', symbol: 'DA' },
  { name: 'Mauritanian ouguiya', symbol: 'UM' }, { name: 'Moroccan dirham', symbol: 'DH' }, 
  { name: 'Israeli new shekel', symbol: '₪' }, { name: 'Jordanian dinar', symbol: 'د.أ' },
  { name: 'Brunei dollar', symbol: 'B$' }, { name: 'Singapore dollar', symbol: '₽' }, 
  { name: 'Hong Kong dollar', symbol: '₽' }, { name: 'Swiss franc', symbol: 'Fr' },
  { name: 'Netherlands Antillean guilder', symbol: 'ƒ' }, { name: 'Saint Helena pound', symbol: '£' }, 
  { name: 'Falkland Islands pound', symbol: '£' }, { name: 'Canadian Dollar', symbol: '$' }
];

function NewItinerary() {
  const { user } = useAuth();
  const storage = getStorage();

  const [categories, setCategories] = useState([]);
  const [groupTypes, setGroupTypes] = useState([]);
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [tookFlight, setTookFlight] = useState(false); // New state for flight selection
  const [destinationCountryInputValue, setDestinationCountryInputValue] = useState('');
  const [destinationCountriesFound, setDestinationCountriesFound] = useState([]);
  const [destinationCountry, setDestinationCountry] = useState(null);
  const [departureAirportInputValue, setDepartureAirportInputValue] = useState('');
  const [departureAirportsFound, setDepartureAirportsFound] = useState([]);
  const [departureAirport, setDepartureAirport] = useState(null);
  const [departureFlightCost, setDepartureFlightCost] = useState(0);
  const [currency, setCurrency] = useState(null);
  const [returnAirportInputValue, setReturnAirportInputValue] = useState('');
  const [returnAirportsFound, setReturnAirportsFound] = useState([]);
  const [returnAirport, setReturnAirport] = useState(null);
  const [returnFlightCost, setReturnFlightCost] = useState(0);
  const [category, setCategory] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const [adult, setAdult] = useState(1);
  const [children, setChildren] = useState(0);
  const [coverImage, setCoverImage] = useState(null);
  const [visibility, setVisibility] = useState(null);
  const [userData, setUserData] = useState(null);

  const usersCollectionRef = collection(db, "users");
  const categoriesCollectionRef = collection(db, "categories");
  const groupTypesCollectionRef = collection(db, "groupTypes");
  const itinerariesCollectionRef = collection(db, "itineraries");

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const usersSnapshot = await getDocs(usersCollectionRef);
          const filteredUserDoc = usersSnapshot.docs.find(doc => doc.id === user.uid);
          if (filteredUserDoc) {
            setUserData({ id: filteredUserDoc.id, ...filteredUserDoc.data() });
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
    fetchUserData();
  }, [user]);  

  useEffect(() => {
    const fetchGroupTypes = async () => {
      try {
        const groupTypesSnapshot = await getDocs(groupTypesCollectionRef);
        setGroupTypes(groupTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching group types:', error);
      }
    };
    fetchGroupTypes();
  }, []);  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(categoriesCollectionRef);
        setCategories(categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);  

  const handleCountrySearch = async (input) => {
    if (input) {
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json&addressdetails=1`);
        const countries = response.data.filter(place => place.addresstype === 'country');
        if (countries.length > 0) {
          setDestinationCountriesFound(countries);
          setDestinationCountry(countries[0]);
        } else {
          alert('No countries found!');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
        setDestinationCountriesFound([]);
      }
    } else {
      alert("Empty input!");
    }
  };

  const getAirports = async (input, setAirportsFound) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${input}&format=json`);
      const airports = response.data.filter(place => place.type === 'aerodrome');
      if (airports.length > 0) {
        setAirportsFound(airports);
      } else {
        alert('No airports found!');
      }
    } catch (error) {
      console.error('Error fetching airports:', error);
      setAirportsFound([]);
    }
  };

  const handleAirportSearch = (inputValue, setInputValue, setAirportsFound) => {
    if (inputValue) {
      getAirports(inputValue, setAirportsFound);
    } else {
      alert("Empty input!");
    }
  };

  const handleSelectAirport = (index, airportsFound, setAirport) => {
    setAirport(airportsFound[index]);
  };

  const handleCreate = async () => {
    if (title && groupType && category && visibility !== null && 
        (tookFlight ? (departureAirport && returnAirport && departureFlightCost && returnFlightCost) : destinationCountry)) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true
        };
  
        const compressedFile = await imageCompression(coverImage, options);
  
        const imageRef = ref(storage, `images/${new Date().toISOString() + compressedFile.name}`);
        await uploadBytes(imageRef, compressedFile);
        const downloadURL = await getDownloadURL(imageRef);
  
        await addDoc(itinerariesCollectionRef, {
          user: doc(db, 'users', userData.id),
          title,
          shortDescription,
          currency: worldCurrencies[currency],
          budget: tookFlight ? departureFlightCost + returnFlightCost : 0, // Set budget to 0 if no flight
          tookFlight,
          departureAirport,
          departureFlightCost,
          returnAirport,
          returnFlightCost,
          destinationCountry: tookFlight ? returnAirport.display_name.split(',').pop() : destinationCountry.display_name,
          groupType: doc(db, 'groupTypes', groupType),
          category: doc(db, 'categories', category),
          participants: { adult, children },
          coverImage: downloadURL,
          completed: false,
          visibility,
          creationDate: new Date()
        });
        alert('Itinerary created!');
        window.location.href = '/';
      } catch (err) {
        console.error('Error creating itinerary:', err);
        alert('Failed to create itinerary. Please try again.');
      }
    } else {
      alert('Please fill in all required fields.');
    }
  };

  return (
    <>
      <div className='row'>
        <div className='column-layout-left'>
          <SideBar />
        </div>
        <div className='column-layout-middle'>
          <TopBar />
          <div className='signup'>
            <br />
            {user ? (
              <>
                <h2>Create a new Itinerary</h2>
                <br /><br />
                <div>
                  <p>Title (Max 40 characters)</p>
                  <input
                    type="text"
                    id="title"
                    maxLength="40"
                    placeholder="Insert itinerary title..." 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <br />
                <div>
                  <p>Short Description (Max 200 characters)</p>
                  <textarea
                    id="shortDescription"
                    rows="5"
                    maxLength="200"
                    placeholder="Insert short description..." 
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </div>
                <br />
                <div>
                  <p>Currency</p>
                  <select onChange={(e) => setCurrency(e.target.value)}>
                    <option selected disabled>Select currency</option>
                    {worldCurrencies.map((currency, index) => (
                      <option key={index} value={index}>{`${currency.name} (${currency.symbol})`}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <p>Group Type</p>
                  <select
                    id="groupType"
                    value={groupType}
                    onChange={(e) => setGroupType(e.target.value)}
                  >
                    <option selected disabled>Select group type</option>
                    {groupTypes.map((groupType, index) => (
                      <option key={index} value={groupType.id}>{groupType.name}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <p>Category</p>
                  <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option selected disabled>Select itinerary category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <p>Did you take a flight?</p>
                  <select onChange={(e) => setTookFlight(e.target.value === 'yes')}>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
                <br />
                {tookFlight ? (
                  <>
                    <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                      <h3>Departure Airport</h3>
                      <br />
                      <p>Search Departure Airport</p>
                      <input
                        type="text"
                        id="departureAirport"
                        placeholder="Insert departure airport..." 
                        value={departureAirportInputValue}
                        onChange={(e) => setDepartureAirportInputValue(e.target.value)}
                      />
                      <br /><br />
                      <button onClick={() => handleAirportSearch(departureAirportInputValue, setDepartureAirportInputValue, setDepartureAirportsFound)}>Find Airports</button>
                      <br /><br />
                      <p>Select Departure Airport</p>
                      <select onChange={(e) => handleSelectAirport(e.target.value, departureAirportsFound, setDepartureAirport)}>
                        <option selected disabled>Select departure airport</option>
                        {departureAirportsFound.map((airport, index) => (
                          <option key={index} value={index}>{airport.display_name}</option>
                        ))}
                      </select>
                      <br /><br />
                      <p>Departure Flight Cost</p>
                      <input
                        type="number"
                        id="departureFlightCost"
                        placeholder="Insert departure flight cost..." 
                        value={departureFlightCost}
                        onChange={(e) => setDepartureFlightCost(Number(e.target.value))}
                      />
                    </div>
                    <br /><br />
                    <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                      <h3>Return Airport</h3>
                      <br />
                      <p>Search Return Airport</p>
                      <input
                        type="text"
                        id="returnAirport"
                        placeholder="Insert return airport..." 
                        value={returnAirportInputValue}
                        onChange={(e) => setReturnAirportInputValue(e.target.value)}
                      />
                      <br /><br />
                      <button onClick={() => handleAirportSearch(returnAirportInputValue, setReturnAirportInputValue, setReturnAirportsFound)}>Find Airports</button>
                      <br /><br />
                      <p>Select Return Airport</p>
                      <select onChange={(e) => handleSelectAirport(e.target.value, returnAirportsFound, setReturnAirport)}>
                        <option selected disabled>Select return airport</option>
                        {returnAirportsFound.map((airport, index) => (
                          <option key={index} value={index}>{airport.display_name}</option>
                        ))}
                      </select>
                      <br /><br />
                      <p>Return Flight Cost</p>
                      <input
                        type="number"
                        id="returnFlightCost"
                        placeholder="Insert return flight cost..." 
                        value={returnFlightCost}
                        onChange={(e) => setReturnFlightCost(Number(e.target.value))}
                      />
                    </div>
                    <br /><br />
                  </>
                ) : (
                  <>
                    <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                      <h3>Destination Country</h3>
                      <br />
                      <p>Search Destination Country</p>
                      <input
                        type="text"
                        id="destinationCountry"
                        placeholder="Insert destination country..." 
                        value={destinationCountryInputValue}
                        onChange={(e) => setDestinationCountryInputValue(e.target.value)}
                      />
                      <br /><br />
                      <button onClick={() => handleCountrySearch(destinationCountryInputValue)}>Find Countries</button>
                      <br /><br />
                      <p>Select Destination Country</p>
                      <select 
                        value={destinationCountry ? destinationCountry.display_name : ''}
                        onChange={(e) => setDestinationCountry(destinationCountriesFound[e.target.value])}>
                        <option selected disabled>Select destination country</option>
                        {destinationCountriesFound.map((country, index) => (
                          <option key={index} value={index}>{country.display_name}</option>
                        ))}
                      </select>
                    </div>
                    <br /><br />
                  </>
                )}
                <div style={{ border: '1px solid black', padding: '2% 0 3% 0', borderRadius: '10px' }}>
                  <h3>Participants</h3>
                  <br />
                  <p>Adult</p>
                  <select
                    id="adult"
                    value={adult}
                    onChange={(e) => setAdult(Number(e.target.value))}
                  >
                    {[...Array(10).keys()].map(index => (
                      <option key={index + 1} value={index + 1}>{index + 1}</option>
                    ))}
                  </select>
                  <br />
                  <p>Children</p>
                  <select
                    id="children"
                    value={children}
                    onChange={(e) => setChildren(Number(e.target.value))}
                  >
                    {[...Array(10).keys()].map(index => (
                      <option key={index} value={index}>{index}</option>
                    ))}
                  </select>
                </div>
                <br /><br />
                <div>
                  <p>Image</p>
                  <input
                    type="file"
                    id="coverImage"
                    onChange={(e) => setCoverImage(e.target.files[0])}
                  />
                </div>
                <br />
                <div>
                  <p>Visibility</p>
                  <select
                    id="visibility"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value === "true" ? true : false)}
                  >
                    <option selected disabled>Select visibility</option>
                    <option value={true}>Public</option>
                    <option value={false}>Private</option>
                  </select>
                </div>
                <br /><br />
                <button onClick={handleCreate}>Create</button>
              </>
            ) : (
              <>
                <br />
                <p>You need to be logged in to create a new itinerary!</p>
                <br />
                <a href='/login'><button>Login</button></a>
              </>
            )}
          </div>
          <BottomNav />
        </div>
        <div className='column-layout-right'></div>
      </div>
      <br /><br />
      <Footer />
    </>
  );
}

export default NewItinerary;