import React from 'react';

import SideBar from '../components/SideBar';

function NotFound() {
  return (
    <div className='row'>
      <div className='column-layout-left'>
        <SideBar />
      </div>
      <div className='column-layout-middle'>
        <p>The page you are looking for has been deleted or does not exist.</p>
      </div>
      <div className='column-layout-right'>

      </div>
    </div>
  )
}

export default NotFound;